import React, { useState, useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { useValidPassword } from '../../hooks/useAuthHooks'
import { Password, ConfirmPassword } from '../../components/authComponents'
import { AuthContext } from '../../contexts/authContext'
import config from '../../config';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
})

export default function ChangePassword() {
  const classes = useStyles()
  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const auth = useContext(AuthContext);

  const {
    password: oldPassword,
    setPassword: setOldPassword,
    passwordIsValid: oldPasswordIsValid,
  } = useValidPassword('')

  const {
    password: newPassword,
    setPassword: setNewPassword,
    passwordIsValid: newPasswordIsValid,
  } = useValidPassword('')

  const {
    password: confirmPassword,
    setPassword: setConfirmPassword,
    passwordIsValid: confirmPasswordIsValid,
  } = useValidPassword('')

  const isValid = !oldPasswordIsValid 
                || oldPassword.length === 0 
                || !newPasswordIsValid 
                || newPassword.length === 0 
                || !confirmPasswordIsValid 
                || confirmPassword.length === 0
                || newPassword !== confirmPassword;

  const history = useHistory()

  const authContext = useContext(AuthContext)

  const changePassword = async () => {
    try {
      setLoading(true);

      const url = `${config.API_URL}/admin-change-password`; 

      const params = {
        oldPassword,
        newPassword,
        email: localStorage.getItem('user_email')
      }

      const response = await axios.post(
        url,
        params,
        {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
            }  
        },
      );

      if(response.status===200){
        setLoading(false);
        signOut();
      }
    
      //setReset(true)

    } catch (err: any) {
      setLoading(false);
      setError('Password cannot be changed. Please contact the Support Team.')
    }
  }

  const signOut = async () => {
    try {
      await authContext.signOut();
      history.push('/signin')
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const handleClickShowOldPassword = (event:React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setShowOldPassword(!showOldPassword);
  }

  const handleClickShowPassword = (event:React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  }

  const handleClickShowConfirmPassword = (event:React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  }

  const updatePassword = (
    <>
      <Box width="80%" m={1} className="box-password">
        <Password label="Old Password" passwordIsValid={oldPasswordIsValid} setPassword={setOldPassword} showPassword={showOldPassword} />
        
        <span className="lbl-error">{!oldPasswordIsValid ? 'Password format is incorrect' : ''}</span>

        <span className="input-icon" tabIndex={-1} role="button" onClick={(event:React.MouseEvent<HTMLSpanElement>) => handleClickShowOldPassword(event)} >
          {showOldPassword 
          ? 
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path></svg>
          :
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path></svg>
          }
        </span>
      </Box>

      <Box width="80%" m={1} className="box-password">
        <Password label="New Password" passwordIsValid={newPasswordIsValid} setPassword={setNewPassword} showPassword={showPassword} />
        
        <span className="lbl-error">{!newPasswordIsValid ? 'Password format is incorrect' : ''}</span>
        
        <span className="input-icon" tabIndex={-1} role="button" onClick={(event:React.MouseEvent<HTMLSpanElement>) => handleClickShowPassword(event)} >
          {showPassword 
          ? 
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path></svg>
          :
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path></svg>
          }
        </span>
      </Box>

      <Box width="80%" m={1}  className="box-password">
        <ConfirmPassword label="Confirm Password" passwordIsValid={confirmPasswordIsValid} confirmPasswordIsValid={confirmPassword === '' || confirmPassword === newPassword} setPassword={setConfirmPassword} showPassword={showConfirmPassword} />

        <span className="lbl-error">{confirmPassword==='' ? '' : !confirmPasswordIsValid ? 'Password format is incorrect' : confirmPassword !== newPassword ? "The Confirm password doesn't match the New password" : ''}</span>

        <span className="input-icon" tabIndex={-1} role="button" onClick={(event:React.MouseEvent<HTMLSpanElement>) => handleClickShowConfirmPassword(event)} >
          {showConfirmPassword 
          ? 
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path></svg>
          :
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path></svg>
          }
        </span>
      </Box>

      <div className="info-format">
        Your password must have at least 16 alphanumeric characters, 
        <br />
        one upper case, one lower case and at least one special character.
      </div>

      {/* Error */}
      <Box mt={2} style={{marginTop: 10}}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2} style={{marginTop: 0}}>
        <Grid container direction="row">
          {localStorage.getItem('force-change-pwd') === 'false' && (
            <Box m={1}>
              <Button onClick={() => history.goBack()} variant="outlined">
                Cancel
              </Button>
            </Box>
          )}
          <Box m={1}>
            <Button disabled={isValid} color="primary" variant="contained" onClick={changePassword}>
              Change Password
            </Button>
          </Box>
        </Grid>
      </Box>



    </>
  )

  const passwordReset = (
    <>
      <Typography variant="h5">{`Password Changed`}</Typography>

      <Box m={4}>
        <Button onClick={signOut} color="primary" variant="contained">
          Sign In
        </Button>
      </Box>
    </>
  )

  return (
    <Grid className={classes.root} container direction="row" style={{justifyContent: 'center', alignItems: 'center'}}>

      {loading && (<div className="circular-progress"><CircularProgress /></div>)}

      <Grid xs={11} sm={6} lg={4} container direction="row" item style={{justifyContent: 'center', alignItems: 'center'}}>
        <Paper style={{ width: '100%'}}>
          <Grid container direction="column" style={{justifyContent: 'center', alignItems: 'center', minHeight: 430}}>
            {/* Title */}
            <Box m={3} style={{marginTop: 0}}>
              <Grid container direction="row">
               {/*  <img src="assets/images/logo-golive.png" alt="" style={{width: 250}} /> */}
               <img src="assets/images/systrategy_logo.svg" style={{width: 250}} alt=""/>
              </Grid>
            </Box>

            {!reset ? updatePassword : passwordReset}

            <div className="logo-sys" style={{marginTop: 10}}>        
                <a href="https://www.systrategy.it" target="_blank" rel="noreferrer"><img src="assets/images/systrategy_logo_header.svg" alt="" style={{width: 100}} /></a>
            </div>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

import React, { useState, useEffect, useContext } from 'react'
import "./campaignDetail.css";
import config from '../../config';
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../contexts/authContext';
import { Country } from '../../App';
import axios from 'axios';
import CommonUtilities from "../../components/CommonUtilities";
import CircularProgress from '@mui/material/CircularProgress';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { useParams } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Draggable from 'react-draggable';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const daypartLabels = [
    {key: 1, value: "Until 3pm"},
    {key: 2, value: "From 3pm"},
    {key: 3, value: "6.00am - 12.00pm"},
    {key: 4, value: "12.00pm - 6.00pm"},
    {key: 5, value: "6.00pm - 12.00am"},
    {key: 6, value: "6.00am - 12.00pm"},
    {key: 7, value: "12.00pm - 6.00pm"},
    {key: 8, value: "6.00pm - 12.00am"}
];

const groupLabels = [
    {key: "group.be.eurostar", value: "Thalys-Eurostar area"},
    {key: "group.be.level0.entry", value: "Level 0 Entry"},
    {key: "group.be.level0.exit", value: "Level 0 Exit"},
    {key: "group.be.level0.hall", value: "Level 0 Hall"},
    {key: "group.be.level1", value: "Level 1"},
    {key: "group.be.entry", value: "Entrance"},
    {key: "group.be.international-trains", value: "International Trains"},
    {key: "group.be.platform", value: "Platform"},
    {key: "group.be.level3", value: "Level 3"},
    {key: "group.be.level0", value: "Level 0"},
    {key: "group.be.level-1", value: "Level -1"},
    {key: "group-be-panel", value: "Panel"}
];

const theme = createTheme({
  palette: {
    secondary: {
      main: red[500],
    },
  },
});

const BOUNCE_TIMEOUT = 1500;

function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-campaign-dett"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
}

const CampaignDetail = (props) =>{
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [country, setCountry] = useContext(Country); 
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [campaign, setCampaign] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [signedUrl, setSignedUrl] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [previewWidth, setPreviewWidth] = useState(360);
    const [previewHeight, setPreviewHeight] = useState(640);
    const [ccCampaignId, setCcCampaignId] = useState("");
    const [bcCampaignId, setBcCampaignId] = useState("");
    const [updating, setUpdating] = useState(false);
    const [handlerDebounce, setHandlerDebounce] = useState(null);
    const [openApproveCampaign, setOpenApproveCampaign] = useState(false);
    const [openRejectCampaign, setOpenRejectCampaign] = useState(false);
    const [rejectReason, setRejectReason] = useState("");
    const [tabValue, setTabValue] = useState(0);
    const [logRow, setLogRow] = useState([]);

    const { kCampaign } = useParams();

    useEffect(() =>  {   
        loadCampaign();
    }, []);

    const loadCampaign = () => {

        setLoading(true);

        let url = `${config.API_URL}/admin-campaign`;

        let params = {
            kCampaign,
            savedData: 'Y'
        };

        axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            }
        ).then((response) => {
            setCampaign(response.data);
            setCcCampaignId(response.data.CC_CAMPAIGN_ID);
            setBcCampaignId(response.data.BC_CAMPAIGN_ID);
        }, (error) => {

            if(error?.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }

        });

        url = `${config.API_URL}/log-trace`;
        
        axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            }
        ).then((response) => {
            //console.log('response.data', response.data)
            setLogRow(response.data);
            setLoading(false);
        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }
        });
    }

    const goToUserDetail = (event, kUser) => {
        event.preventDefault();
        history.push(`/user/${kUser}`)
    }

    const thumbList = (creativeId, kCountry) => {
        const url = `${config.CONTENT_DISTRIBUTION_URL}/thumbs/${creativeId}.png`;
        return( 
            <div className="thumb-list">
                <div className="box">
                    <Tooltip title="Portrait preview">
                        <img src={url} alt="" className="thumb portrait" onClick={() => openPreview("portrait", creativeId)} />
                    </Tooltip>
                    <Tooltip title="Portrait download">
                        <div className="download-link" onClick={() => downloadContent("portrait", creativeId)}  ><CloudDownloadIcon /></div>
                    </Tooltip>
                </div>

                {kCountry === 1 && (
                    <div className="box">
                        <Tooltip title="Landscape preview">
                            <img src={url} alt="" className="thumb landscape" onClick={() => openPreview("landscape", creativeId)} />
                        </Tooltip>
                        <Tooltip title="Landscape download">
                            <div className="download-link" onClick={() => downloadContent("landscape", creativeId)} ><CloudDownloadIcon /></div>
                        </Tooltip>
                </div>
                )}
            </div>);
    }

    const handleOpenApproveCampaign = () => {
        setOpenApproveCampaign(true);
    };

    const handleOpenRejectCampaign = () => {
        setRejectReason("");
        setOpenRejectCampaign(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseApproveCampaign = () => {
        setOpenApproveCampaign(false);
    };

    const handleCloseRejectCampaign = () => {
        setRejectReason("");
        setOpenRejectCampaign(false);
    };
    

    const downloadContent = (orientation, fileName) => {
        setLoading(true);
        const url = `${config.API_URL}/content`;

        const params = {
            contentUrl: `videos/${orientation}/${fileName}.mp4`
        };

        axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            }
        ).then((response) => {

            const signedUrl = response.data;

            fetch(signedUrl)
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = `${fileName}-${orientation}.mp4`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                })
            .catch((error) => console.error(error)); 

            setLoading(false);

        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }
            setLoading(false);
        });
    }

    const openPreview = (orientation, fileName) => {
        setLoading(true);

        if(orientation === "landscape"){
            setPreviewTitle("Landscape preview")
            setPreviewWidth(640);
            setPreviewHeight(360);
        }else{
            setPreviewTitle("Portrait preview")
            setPreviewWidth(360);
            setPreviewHeight(640);
        }

        const url = `${config.API_URL}/content`;

        const params = {
            contentUrl: `videos/${orientation}/${fileName}.mp4`
        };

        axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            }
        ).then((response) => {

            setSignedUrl(response.data);

            setOpen(true);
            setLoading(false);

        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }
            setLoading(false);
        });
    };

    
    const modCampaign = (myCcCampaignId, myBcCampaignId) => {
        setUpdating(true);

        const url = `${config.API_URL}/admin-campaign-mod`;

        const params = {
            kCampaign,
            ccCampaignId: myCcCampaignId,
            bcCampaignId: myBcCampaignId
        };

        axios.post(
            url,
            params,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                }  
            },

        ).then((response) => {
            setUpdating(false);

        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }
        });
    }

    const modCampaignStatus = (statusCod) => {
        setUpdating(true);

        const url = `${config.API_URL}/admin-campaign-status-mod`;

        const params = {
            kCampaign,
            statusCod,
            rejectReason
        };

        axios.post(
            url,
            params,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                }  
            },
        ).then((response) => {
            handleCloseApproveCampaign();
            handleCloseRejectCampaign();
            
            setUpdating(false);
            loadCampaign();

        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }
        });
    }

    const handleChangeCCCampaignId = (event) => {
        setCcCampaignId(event.target.value);
        clearTimeout(handlerDebounce);
        setHandlerDebounce(setTimeout(() => modCampaign(event.target.value, bcCampaignId), BOUNCE_TIMEOUT));
    };

    const handleChangeBcCampaignId = (event) => {
        setBcCampaignId(event.target.value);
        clearTimeout(handlerDebounce);
        setHandlerDebounce(setTimeout(() => modCampaign(ccCampaignId, event.target.value), BOUNCE_TIMEOUT));
    };
    

    const handleChangeRejectReason = (event) => {
        setRejectReason(event.target.value);
    };

    const handleChangeTab = (event, value) => {
        setTabValue(value);
    }

    const getSubPanelList = (arrPanels) => {

        return(
            <div>
                <div className="group-header">{groupLabels.find(item => item.key === arrPanels[0].groupLabel).value}</div> 
                <table className="tb-panels">
                    <header>
                        <tr>
                            <th style={{width: 70}}>Panel Id</th>
                            <th style={{width: 120}}>Digital Id</th>
                        </tr>
                    </header>
                    <body>
                    {
                        arrPanels.map((panel) => (
                            <tr>
                                <td style={{width: 70}}>{panel.frameId}</td>
                                <td style={{width: 120}}>{panel.digitalId}</td>
                            </tr>
                        ))
                    }
                    </body>
                </table>   
            </div>         
        )
    }

    const getPanelList = () => {
        let html = [];

        const arrPanels = JSON.parse(campaign.PANELS_SELECTED);

        let arrPanelSelected = [];

        if(campaign.PANELS_LIST !== ''){
            arrPanelSelected = campaign.PANELS_LIST.split(',');
        }

        let arrSubPanels = [];
        let oldGroupLabel = "";

        for(let i=0; i<arrPanels.length; i++){
            if(arrPanels[i].groupLabel !== oldGroupLabel){
                if(oldGroupLabel !== "" && arrSubPanels.length > 0){
                    html.push(getSubPanelList(arrSubPanels));
                    arrSubPanels = [];
                }
                oldGroupLabel = arrPanels[i].groupLabel;
            }

            if(arrPanelSelected.indexOf(arrPanels[i].kFace.toString()) >= 0){
                arrSubPanels.push(arrPanels[i]);
            }
        } 

        if(arrSubPanels.length >0){
            html.push(getSubPanelList(arrSubPanels));
        }

        return html;
    }

    const gotoMainList = () => {
        history.push(`/campaigns`)
    }

    const flagRejectMsg = (country.split('|')[2]) === 'Y' ? true : false;

    return (
        <div className="campaign-detail">
            <ThemeProvider theme={theme}>
                {loading &&  (<div className="circular-progress"><CircularProgress /></div>)}

                {updating && (
                    <Box sx={{ width: '100%' }} style={{position: "fixed", top: 0, zIndex: 10000}}>
                        <LinearProgress />
                    </Box>
                )}

                <Dialog
                    open={openApproveCampaign}
                    onClose={handleCloseApproveCampaign}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-campaign-dett"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-campaign-dett">
                        Approve Campaign
                    </DialogTitle>
                    <DialogContent>
                        Do you want to Approve the campaign?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleCloseApproveCampaign}>Cancel</Button>
                        <Button variant="contained"  onClick={() => modCampaignStatus(4)}>Approve</Button>
                    </DialogActions>    
                </Dialog>

                <Dialog
                    open={openRejectCampaign}
                    onClose={handleCloseRejectCampaign}
                    PaperComponent={PaperComponent}
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-campaign-dett">
                        Reject Campaign
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you want to reject the campaign?
                        </DialogContentText> 
                        {flagRejectMsg && (
                            <TextField
                                autoFocus
                                margin="dense"
                                id="reason"
                                label="Reason"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={rejectReason}
                                onChange={handleChangeRejectReason}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleCloseRejectCampaign}>Cancel</Button>
                        <Button variant="contained" 
                                disabled={flagRejectMsg && rejectReason === ''}
                                onClick={() => modCampaignStatus(1)}>Reject</Button>
                    </DialogActions>    
                </Dialog>


                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-campaign-dett">
                        {previewTitle}
                    </DialogTitle>
                    <DialogContent>
                        {signedUrl !== "" && (
                            <video controls autoplay="true" width={previewWidth} height={previewHeight}>
                                <source src={signedUrl} type="video/mp4" />
                            </video>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>Close</Button>
                    </DialogActions>    
                </Dialog>
                {campaign !== undefined && (
                    <Card style={{width: '100%'}}>
                        <div className='custom-detail-header'>
                            <IconButton className='back-toList-btn' onClick={gotoMainList}>
                                <ArrowBackIcon/>
                            </IconButton>
                            <CardHeader
                                title={`Campaign: ${kCampaign}`}
                            />
                        </div>
                        <Tabs value={tabValue} sx={{marginLeft: '16px'}} onChange={handleChangeTab} aria-label="basic tabs example">
                            <Tab label="Detail" id='simple-tab-1'/>
                            <Tab label="Log" id='simple-tab-2'/>
                        </Tabs>
                        {tabValue===0 ? (
                        <CardContent>
                            <Paper style={{maxWidth: 600}}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="left">Gateway Contract ID:</TableCell>
                                                <TableCell align="left"><strong>{campaign.KCAMPAIGN}</strong></TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>{campaign.KCOUNTRY === 2 ? "AIDA order line ID" : "Clear Channel Campaign ID"}:</TableCell>
                                                <TableCell align="left">
                                                    <TextField
                                                        variant="standard"
                                                        id="ccCampaignId"
                                                        value={ccCampaignId}
                                                        onChange={handleChangeCCCampaignId}
                                                    />                                            
                                                </TableCell>
                                            </TableRow>

                                            {campaign.KCOUNTRY === 2 && (
                                                <TableRow>
                                                    <TableCell>Broadsign Control Campaign ID:</TableCell>
                                                    <TableCell align="left">
                                                        <TextField
                                                            variant="standard"
                                                            id="ccCampaignId"
                                                            value={bcCampaignId}
                                                            onChange={handleChangeBcCampaignId}
                                                        />                                            
                                                    </TableCell>
                                                </TableRow>
                                            )}

                                            <TableRow>
                                                <TableCell align="left">Status:</TableCell>
                                                <TableCell align="left">{campaign.STATUS_DESC === 'CONFIRMED' ? 'AWAITING PAYMENT' : campaign.STATUS_DESC}{campaign.REJECTED === 'Y' ? ' (reject.)' : ''}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Campaign Name:</TableCell>
                                                <TableCell align="left">{campaign.DCAMPAIGN}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Country:</TableCell>
                                                <TableCell align="left">{campaign.DCOUNTRY}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Location:</TableCell>
                                                <TableCell align="left">{campaign.LISTA_DLOCATION}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Begin Date:</TableCell>
                                                <TableCell align="left">{moment(campaign.BEGIN_DATE).format('MMMM Do YYYY')}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">End Date:</TableCell>
                                                <TableCell align="left">{moment(campaign.END_DATE).format('MMMM Do YYYY')}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">User:</TableCell>
                                                <TableCell align="left"><a href="#" onClick={(event) => goToUserDetail(event, campaign.KUSER_CREATION)}>{campaign.DUSER_CREATION}</a></TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Creation Date:</TableCell>
                                                <TableCell align="left">{CommonUtilities.getFormatDateTime(campaign.DATA_INSERIMENTO, 'MMMM Do YYYY HH:mm:ss')}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Last Edit Date:</TableCell>
                                                <TableCell align="left">{CommonUtilities.getFormatDateTime(campaign.DATA_ULTIMA_MODIFICA, 'MMMM Do YYYY HH:mm:ss')}</TableCell>
                                            </TableRow>

                                            <TableRow className="row-creative">
                                                <TableCell align="left">Creativity:</TableCell>
                                                <TableCell align="left">
                                                    {campaign.CUSTOM_CREATIVE_ID !== null ? thumbList(campaign.CUSTOM_CREATIVE_ID, campaign.KCOUNTRY) : campaign.GLOOH_RENDERED === 'Y' ? thumbList(campaign.GLOOH_CREATIVE_ID, campaign.KCOUNTRY) : 'Rendering'}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Level:</TableCell>
                                                <TableCell align="left">{campaign.FRAMES_FLOOR}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Giant frames:</TableCell>
                                                <TableCell align="left">{campaign.FRAMES_GIANT === 'Y' ? 'Yes' : 'No'}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Perimeter:</TableCell>
                                                <TableCell align="left">
                                                    {campaign.KCOUNTRY === 2 || campaign.FRAMES_ALL === 'Y' ? 'All the frames in the perimeter' : 'Half of the frames in the perimeter'}
                                                    <br />
                                                    {campaign.KCOUNTRY === 2 && (
                                                        <div>
                                                            {getPanelList()}
                                                        </div>
                                                    )}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Daypart:</TableCell>
                                                <TableCell align="left">{campaign.KDAYPART === null ? "Full day" : daypartLabels.find(item => item.key === campaign.KDAYPART).value }</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Number of frames:</TableCell>
                                                <TableCell align="left">{campaign.FRAMES_SELECTED}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Number of spots:</TableCell>
                                                <TableCell align="left">{campaign.NUMBER_OF_SPOT}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Group list:</TableCell>
                                                <TableCell align="left">{campaign.GROUP_LIST}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Stripe Intent Id:</TableCell>
                                                <TableCell align="left">{campaign.STRIPE_CLIENT_SECRET}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Cost:</TableCell>
                                                <TableCell align="left">{CommonUtilities.formatNumber(campaign.TOTAL_COST, 'en-IN', {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}</TableCell>
                                            </TableRow>

                                            {campaign.STATUS_COD === 3 && (
                                                <TableRow>
                                                    <TableCell align="left">
                                                        <Button variant="contained" size="small" color="secondary" endIcon={<ThumbDownIcon />} onClick={handleOpenRejectCampaign}>Reject</Button>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Button variant="contained" size="small" color="success" endIcon={<ThumbUpIcon />} onClick={handleOpenApproveCampaign}>Approve</Button>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>                        
                            </Paper>
                        </CardContent>)
                        :
                        (<CardContent>
                            <Paper style={{maxWidth: 600}}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell>User</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                            logRow.map((log) => (
                                                <TableRow
                                                    key={log.KLOG}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="left">{CommonUtilities.formatDateTime(log.DATA_LOG)}</TableCell>
                                                    <TableCell align="left">{log.INVOKER_KUSER===-19 ? log.DUSER : log.INVOKER_DUSER}</TableCell>
                                                    <TableCell align="left">{log.DACTION}</TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </CardContent>)}
                    </Card>    
                )}                    
            </ThemeProvider>
        </div>
    )
}

export default CampaignDetail;

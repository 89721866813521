import React, { useState, useEffect, useContext } from 'react'
import "./promoCodeList.css";
import config from '../../config';
import { alpha, styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../contexts/authContext';
import { Country } from '../../App';
import moment from 'moment';
import axios from 'axios';
import CommonUtilities from "../../components/CommonUtilities";
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import { Dialog, DialogActions, DialogContent, DialogTitle, Input } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import labelData from '../../labels.json'

const theme = createTheme({
    palette: {
        primary: {
            main: "#0c86c4",
        },    
    },
});

const PromoCodeList = () => {

    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [country, setCountry] = useContext(Country);    
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [selectedHeader, setSelectedHeader] = useState(localStorage.getItem('sort-header')!==null ? localStorage.getItem('sort-header') : 'KPROMO_CODE');
    const [sortDirection, setSortDirection] = useState(localStorage.getItem('sort-direction')!==null ? localStorage.getItem('sort-direction') : 'desc');
    const [page, setPage] = useState(localStorage.getItem('page')!==null ? parseInt(localStorage.getItem('page')) : 1);
    const [firstRecord, setFirstRecord] = useState(localStorage.getItem('firstRecord')!==null ? parseInt(localStorage.getItem('firstRecord')) : 1);
    const [numRecords, setNumRecord] = useState(50);
    const [numPages, setNumPages] = useState(1);
    const [searchBy, setSearchBy] = useState(localStorage.getItem('filter-type')!==null ? parseInt(localStorage.getItem('filter-type')) : 1);
    const [searchTxt, setSearchTxt] = useState(localStorage.getItem('filter-val')!==null ? localStorage.getItem('filter-type')=== '1' ? localStorage.getItem('filter-val') : "" : "");
    const [handlerDebounce, setHandlerDebounce] = useState(null);
    const [searchStatusCod, setSearchStatusCod] = useState(localStorage.getItem('filter-val')!==null ? localStorage.getItem('filter-type')=== '2' ? parseInt(localStorage.getItem('filter-val')) : 0 : 0);
    const [addPromoCodeDialog, setAddPromoCodeDialog] = useState(false);

    //stati dialog add promo code
    const [diaDateRange, setDiaDateRange] = useState([undefined, undefined]);
    const [diaPromoCode, setDiaPromoCode] = useState('');
    const [diaDiscount, setDiaDiscount] = useState('');
    const [diaComment, setDiaComment] = useState('');
    const [diaDateError, setDiaDateError] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [diaQuantityUsage, setDiaQuantityUsage] = useState(1);
    const [diaDiscountType, setDiaDiscountType] = useState(1);

    const labels = labelData.labels;

    useEffect(() => {
        localStorage.removeItem('page');
        localStorage.removeItem('firstRecord');
        localStorage.removeItem('filter-type');
        localStorage.removeItem('filter-val');
        localStorage.removeItem('userListTab');
        localStorage.removeItem('sort-header');
        localStorage.removeItem('sort-direction');
    }, [])

    useEffect(() => {
        if(loading)loadGrid();
    }, [firstRecord, selectedHeader, sortDirection])

    useEffect(() => {
        if(!loading && searchBy === 1){
            clearTimeout(handlerDebounce);
            setHandlerDebounce(setTimeout(() => {
                loadGrid();
            }, 600));
        }
    }, [searchTxt]);

    useEffect(() => {
        if(!loading){
            loadGrid();
        }
    }, [searchBy, searchStatusCod]);

    useEffect(() => {
        if(addPromoCodeDialog && diaDateRange[0] && diaDateRange[1]){
            
            const startDate = new Date(diaDateRange[0].getFullYear(), diaDateRange[0].getMonth(), diaDateRange[0].getDate());
            const endDate = new Date(diaDateRange[1].getFullYear(), diaDateRange[1].getMonth(), diaDateRange[1].getDate());


            if(parseInt(CommonUtilities.getStringFromDate(diaDateRange[0])) < parseInt(moment().format('yyyyMMDD'))){
                setDiaDateError("Start date can't be lower than today");
            }else if(endDate < startDate){
                setDiaDateError("End date can't be lower than start date");
            }else{
                setDiaDateError('');
            }
        }
    }, [diaDateRange])

    const loadGrid = () => {
        setLoading(true);

        const url = `${config.API_URL}/promo-codes`;

        let promoCode = "";
        let statusCod = "";
        if(searchBy === 1){
            promoCode = searchTxt;
        }else if(searchBy === 2){
            statusCod = searchStatusCod !== 0 ? searchStatusCod : "";
        }
        
        const kCountry = country.split('|')[0];

        const params = {
            kCountry,
            promoCode,
            statusCod,
            firstRecord,
            numRecords,
            orderByField: selectedHeader,
            orderByMode: sortDirection.toUpperCase()
        };

        axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            }
        ).then((response) => {
            if(response.data.length > 0){
                setRows(response.data);
                setNumPages(Number.isInteger(response.data[0].NUM_TOT/numRecords) ? response.data[0].NUM_TOT/numRecords : Math.floor(response.data[0].NUM_TOT/numRecords) + 1);
            }else{
                setRows([]);
                setNumPages(0);
            }
            setLoading(false);
        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }
        });
    }

    const insPromoCode = async() => {
        setLoading(true);

        setErrorMsg('');
        setDiaDateError('');

        const url = `${config.API_URL}/promo-code-ins`;
        const kCountry = country.split('|')[0];

        const params = {
            kCountry,
            beginDate: CommonUtilities.getStringFromDate(diaDateRange[0]),
            endDate: CommonUtilities.getStringFromDate(diaDateRange[1]),
            promoCode: diaPromoCode,
            discount: diaDiscount,
            dComment: diaComment,
            pQuantityByUser: diaQuantityUsage,
            pDiscountType: diaDiscountType
        };

        axios.post(
            url,
            params,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                } 
            }
        ).then((response) => {
            if(response.status===200){
                handleCloseAddPromoDialog();
                loadGrid();
            }
            setLoading(false);
        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                
                if(error.response.data === -2){
                    setErrorMsg(labels['error-promo-code-ins-2']);
                }else if(error.response.data === -3){
                    setErrorMsg(labels['error-promo-code-ins-3']);
                }else if(error.response.data === -4){
                    setErrorMsg(labels['error-promo-code-ins-4']);                    
                }else{
                    setErrorMsg(labels['error-gen-0']);
                }

                setLoading(false);
            }
        });
    }

    const gotoDetail = (event, kPromoCode) =>  {
        event.preventDefault();
        localStorage.setItem('page', page);
        localStorage.setItem('firstRecord', firstRecord);
        localStorage.setItem('filter-type', searchBy);
        localStorage.setItem('filter-val', searchBy===1 ? searchTxt : searchStatusCod);
        localStorage.setItem('sort-header', selectedHeader);
        localStorage.setItem('sort-direction', sortDirection);
        history.push(`/promo-code/${kPromoCode}`);
    }

    const sortTable = (columnId, sortDirection) => {
        let sortValue = ''
        //controllo per resettare a desc il sorting nel caso si cliccasse su un header differente dal precedente
        if(columnId !== selectedHeader){
            sortValue = 'desc';
        }else{
            sortValue = sortDirection==='desc' ? 'asc' : 'desc';
        }
        setSortDirection(sortValue);
        setSelectedHeader(columnId);
    };

    const chipClass = (row) => {
        if(row.STATUS_DESC === 'Not started'){
            return 'tag-created';
        }
        else if(row.STATUS_DESC === 'In progress'){
            return 'tag-waiting';
        }
        else if(row.STATUS_DESC === 'Expired'){
            return 'tag-rejected';
        }
    }

    const handleChangePage = (event,value) => {
        if(value === 1){
            setPage(1);
            setFirstRecord(1)
        }else{
            setPage(value);
            setFirstRecord(((value-1)*numRecords)+1);
        }
    };

    const handleChangeSearchBy = (event) => {
        setSearchBy(event.target.value);
    };

    const handleChangeSearchTxt = (event) => {
        setSearchTxt(event.target.value);
    };

    const handleChangeSearchStatusCod = (event) => {
        setSearchStatusCod(event.target.value);
    };

    const handleOpenAddPromoDialog = () => {
        setDiaDateRange([new Date(), new Date()])
        setDiaPromoCode("");
        setDiaDiscount("");
        setDiaComment("");
        setErrorMsg('');
        setAddPromoCodeDialog(true);
    }

    const handleCloseAddPromoDialog = () => {
        setDiaDateRange([undefined, undefined]);
        setDiaPromoCode("");
        setDiaDiscount("");
        setDiaComment("");
        setErrorMsg('');
        setDiaQuantityUsage(1);
        setDiaDiscountType(1);
        setAddPromoCodeDialog(false);
    }

    const checkValue = (value) => {
        if(diaDiscountType === 1){
            if(value > 100){
                return;
            }else{
                setDiaDiscount(value);
            }
        }else{
            setDiaDiscount(value);
        }
    }

    return (
        <div className="promo-code-list">
            <ThemeProvider theme={theme}>
                {loading && (<div className="circular-progress"><CircularProgress /></div>)}
                
                <Card style={{width: '100%'}}>
                    <CardHeader
                        className='custom-card-head'
                        title="Code Promo"
                        action={<Button
                                sx={{marginLeft:'15px'}}
                                variant="contained"
                                onClick={handleOpenAddPromoDialog}>
                                    ADD PROMO CODE
                                </Button>}
                    />
                    <CardContent>

                        <div className="filters">
                            <FormControl className="frm-ctrl">
                                <Select
                                    value={searchBy}
                                    onChange={handleChangeSearchBy}
                                    variant="standard" 
                                    size="small"
                                    style={{width: 100}}
                                    color="primary"
                                    >
                                        <MenuItem value={1}>Name</MenuItem>
                                        <MenuItem value={2}>Status</MenuItem>
                                </Select>
                            </FormControl>
                            
                            {searchBy === 1 && (
                                <FormControl>
                                    <TextField
                                        placeholder="Search Promo Code by attribute"
                                        value={searchTxt}
                                        onChange={handleChangeSearchTxt}
                                        variant="standard" 
                                        size="small"
                                        style={{width: 250}}
                                        color="primary"
                                    />
                                </FormControl>
                            )}

                            {searchBy === 2 && (
                                <FormControl>
                                    <Select
                                        value={searchStatusCod}
                                        onChange={handleChangeSearchStatusCod}
                                        variant="standard" 
                                        size="small"
                                        style={{width: 200}}
                                        color="primary"
                                        >
                                            <MenuItem value={0}>ALL STATUS</MenuItem>
                                            <MenuItem value={1}>NOT STARTED</MenuItem>
                                            <MenuItem value={2}>IN PROGRESS</MenuItem>
                                            <MenuItem value={3}>EXPIRED</MenuItem>
                                    </Select>
                                </FormControl>
                            )}                            
                        </div>

                        <Paper className="grid-cnt">    
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                id={'KPROMO_CODE'}
                                                align="center"
                                                sx={{width: '100px'}}
                                                >
                                                <TableSortLabel
                                                    id={'KPROMO_CODE'}
                                                    active={selectedHeader === 'KPROMO_CODE' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('KPROMO_CODE', sortDirection)}}
                                                    >Code ID
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'DCOUNTRY'}
                                                align="left"
                                                sx={{width: '100px'}}
                                                >
                                                Country
                                            </TableCell>
                                            <TableCell
                                                id={'BEGIN_DATE'}
                                                align="left"
                                                sx={{width: '100px'}}
                                                >
                                                <TableSortLabel
                                                    id={'BEGIN_DATE'}
                                                    active={selectedHeader === 'BEGIN_DATE' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('BEGIN_DATE', sortDirection)}}
                                                    >From date
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'END_DATE'}
                                                align="left"
                                                sx={{width: '100px'}}
                                                >
                                                <TableSortLabel
                                                    id={'END_DATE'}
                                                    active={selectedHeader === 'END_DATE' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('END_DATE', sortDirection)}}
                                                    >To date
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'PROMO_CODE'}
                                                align="left"
                                                sx={{width: '200px'}}
                                                >
                                                <TableSortLabel
                                                    id={'PROMO_CODE'}
                                                    active={selectedHeader === 'PROMO_CODE' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('PROMO_CODE', sortDirection)}}
                                                    >Promo Code
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'DISCOUNT'}
                                                align="right"
                                                sx={{width: '100px'}}
                                                >
                                                <TableSortLabel
                                                    id={'DISCOUNT'}
                                                    active={selectedHeader === 'DISCOUNT' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('DISCOUNT', sortDirection)}}
                                                    >Discount
                                                </TableSortLabel>
                                            </TableCell>                             
                                            <TableCell
                                                id={'STATUS_DESC'}
                                                align="center"
                                                sx={{width: '175px'}}
                                                >
                                                <TableSortLabel
                                                    id={'STATUS_DESC'}
                                                    active={selectedHeader === 'STATUS_DESC' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('STATUS_DESC', sortDirection)}}
                                                    >Status
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'COMMENT'}
                                                align="left"
                                                sx={{width: '200px'}}
                                                >
                                                Comment
                                            </TableCell>
                                            <TableCell
                                                id={'DATA_INSERIMENTO'}
                                                align="left"
                                                sx={{width: '100px'}}
                                                >
                                                <TableSortLabel
                                                    id={'DATA_INSERIMENTO'}
                                                    active={selectedHeader === 'DATA_INSERIMENTO' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('DATA_INSERIMENTO', sortDirection)}}
                                                    >Creation Date
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="table-body">
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.KPROMO_CODE}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center"><a href="#" onClick={(event) => gotoDetail(event, row.KPROMO_CODE)}>{row.KPROMO_CODE}</a></TableCell>
                                            <TableCell align="left">{row.DCOUNTRY}</TableCell>
                                            <TableCell align="left">{CommonUtilities.getFormatDate(row.BEGIN_DATE)}</TableCell>
                                            <TableCell align="left">{CommonUtilities.getFormatDate(row.END_DATE)}</TableCell>
                                            <TableCell align="left"><a href="#" onClick={(event) => gotoDetail(event, row.KPROMO_CODE)}>{row.PROMO_CODE}</a></TableCell>
                                            <TableCell align="right">{CommonUtilities.formatPositiveIntNum(row.DISCOUNT)}{row.DISCOUNT_TYPE === 2 ? '€' : '%'}</TableCell>
                                            <TableCell align="center"><span className={chipClass(row)}>{row.STATUS_DESC}</span></TableCell>
                                            <TableCell align="left">{row.DCOMMENT}</TableCell>
                                            <TableCell align="left">{CommonUtilities.getFormatDate(row.DATA_INSERIMENTO)}</TableCell>                                                                      
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Pagination count={numPages} siblingCount={0} page={page} variant="outlined" color="primary" size='medium' onChange={handleChangePage}/>
                        </Paper>
                    </CardContent>

                </Card>          

                <Dialog
                    open={addPromoCodeDialog}
                    onClose={() => false}
                >
                    <DialogTitle>
                        New Promo Code
                    </DialogTitle>
                    <DialogContent>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div style={{marginTop:'10px'}}>
                                <DatePicker
                                    label="Start Date"
                                    value={diaDateRange[0]}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newValue) => {
                                        setErrorMsg('');
                                        setDiaDateRange([newValue, diaDateRange[1]]);
                                    }}
                                    renderInput={(params) => <TextField className='date-field' {...params} />}
                                />
                                &nbsp;
                                <DatePicker
                                    label="End Date"
                                    value={diaDateRange[1]}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newValue) => {
                                        setErrorMsg('');
                                        setDiaDateRange([diaDateRange[0], newValue]);
                                    }}
                                    renderInput={(params) => <TextField className='date-field' {...params} />}
                                />
                            </div>
                        </LocalizationProvider>

                        <div style={{display:'flex',alignItems:'bottom', marginTop:5}}>
                            <TextField sx={{width:'100%'}} label="Promo Code" variant="standard" value={diaPromoCode} onChange={(event) => {setErrorMsg(''); setDiaPromoCode(event.target.value);}}></TextField>
                            <Select
                                value={diaQuantityUsage}
                                className="select-to-adj-svg"
                                onChange={(event) => {setErrorMsg(''); setDiaQuantityUsage(event.target.value);}}
                                variant="standard" 
                                size="small"
                                style={{width: 120, marginLeft: 15, alignItems: 'flex-end'}}
                                label="Quantity by User"
                                color="primary"
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={99}>Unlimited</MenuItem>
                            </Select>
                        </div>
                        <div style={{display:'flex',alignItems:'bottom', marginTop:5}}>
                            <TextField sx={{width:'30%'}} type='number' label="Discount" variant="standard" value={diaDiscount} onChange={(event) => checkValue(event.target.value)}></TextField>
                            <Select
                                value={diaDiscountType}
                                className="select-to-adj-svg"
                                onChange={(event) => {setErrorMsg(''); setDiaDiscount(''); setDiaDiscountType(event.target.value);}}
                                variant="standard" 
                                size="small"
                                label=""
                                style={{marginLeft: 10, alignItems: 'flex-end'}}
                                color="primary"
                                >
                                    <MenuItem value={1}>%</MenuItem>
                                    <MenuItem value={2}>€</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <TextField sx={{width:'100%'}} label="Comment" variant="standard" value={diaComment} onChange={(event) => setDiaComment(event.target.value)}></TextField>
                        </div>

                        {diaDateError !== '' 
                        ? 
                            <h5 className="msg-error">{diaDateError}</h5> 
                        :
                            errorMsg !== '' 
                            ?
                            <h5 className="msg-error">{errorMsg}</h5> 
                            :
                            <h5 className="msg-error">&nbsp;</h5>
                        }
                    </DialogContent>
                    <DialogActions sx={{justifyContent:'center'}}>
                        <Button
                            variant="outline"
                            onClick={handleCloseAddPromoDialog}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!diaDateRange[0] || !diaDateRange[1] || diaPromoCode.trim() === '' || diaPromoCode.trim().length < 3  || !diaDiscount || parseInt(diaDiscount) <= 0 || diaDateError!==''}
                            variant="contained"
                            onClick={insPromoCode}
                        >
                            Confirm Promo Code
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>              
        </div>
    )
}

export default PromoCodeList
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import "./sideNav.css";
import config from '../../config';
import { AuthContext } from '../../contexts/authContext';
import { Country } from '../../App';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import {useDropzone} from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import SettingsIcon from '@mui/icons-material/Settings';
import labelData from '../../labels.json'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import CheckIcon from '@mui/icons-material/Check';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const LBL_NO_CONTRACT = "No contract (inherit)";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} id="draggable-dialog-title">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SideNav = (props) => {
  const auth = useContext(AuthContext);
  const [country, setCountry] = useContext(Country);
  const [loading, setLoading] = useState(true);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [treeViewList, setTreeViewList] =  useState([]);
  const [treeViewHeight, setTreeViewHeight] = useState(0);
  const [openSettings, setOpenSettings] = useState(false);
  const [locationFilter, setLocationFilter] = useState("");
  const [handlerDebounce, setHandlerDebounce] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [kItem, setKItem] = useState(0);
  const [kContract, setKContract] = useState(0);
  const [dNoContract, setDNoContract] = useState(LBL_NO_CONTRACT);
  const [kLevel, setKLevel] = useState(0);
  const [dLevel, setDLevel] = useState(0);
  const [showAudience, setShowAudience] = useState(false);
  const [flagRejectMsg, setFlagRejectMsg] = useState(false);
  const [stripeVATActivated, setStripeVATActivated] = useState(false);
  const [mapPhoto, setMapPhoto] = useState('N');
  const [mapFilename, setMapFilename] = useState("");
  const [mapInherit, setMapInherit] = useState(false);
  const [flagEnabled, setFlagEnabled] = useState(false);
  const [reload, setReload] = useState(false);
  const [panelFilename, setPanelFilename] = useState("");
  const [errImgSize, setErrImgSize] = useState("");
  const labels = labelData.labels;

  const updateDimensions = async() => {  
    setTreeViewHeight(window.innerHeight - 130);
  };

  useEffect(() =>  {  
    localStorage.setItem('kItem', 0);
    localStorage.setItem('kLevel', 0);

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    loadTreeView();
    loadContracts();
  }, []);

  useEffect(() => {
    if(!loading){
    //localStorage.removeItem("checkedGroups");
    //localStorage.removeItem("checkedFrames");
    //al contrario perché cambia prima di arrivare qua
    localStorage.setItem('prevCountry', country.split('|')[0] === '1' ? '2' : '1')
      loadTreeView();
      
    }
  }, [country]);

  useEffect(() => {
    if(!loading){
      clearTimeout(handlerDebounce);
      setHandlerDebounce(setTimeout(() => {
        loadTreeView(false, true);
        props.handleChangeCheckedGroups([]);
        props.handleChangeCheckedFrames([]);
      }, 600));
    }
  }, [locationFilter]);

  const loadContracts = async() => {
    const url = `${config.API_URL}/contracts`;
    const params = {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
      },
      params: {}
    };

    const response = await axios.get(url, params);

    if(response.data.length > 0){
      setContracts(response.data);
    }
  }

  const loadTreeView = (forceHardReload = false, forceReloadFromSearchLocation=false) => {
    setLoading(true);
/*     if(localStorage.getItem("checkedFrames")==='[]'){
      loadTreeViewAll();
    } */

    const localKLevel = parseInt(localStorage.getItem("kLevel"));
    const localKItem = parseInt(localStorage.getItem("kItem"));

    //load all epandend groups
    /* let expandedGroups = [];
    if(treeViewList.length>0){
      treeViewList[0].items.forEach((itemLocation) => {
        itemLocation.items.forEach((itemGroup) => {
              if(itemGroup.expanded) {
                expandedGroups.push(itemGroup.groupId);
              }
        });
      });
    } */
    
    const url = `${config.API_URL}/tree-view-list`;

    const kCountry = country.split('|')[0];

    const params = {
      kCountry,
      kLevel: localKLevel,
      kItem: localKItem, 
      locationFilter,
      checkedGroups: '[]',//localStorage.getItem("checkedGroups"),
      //checkedFrames: localStorage.getItem("checkedFrames"),
      expandedGroups: '[]'//JSON.stringify(expandedGroups),
    };

    axios.get(
        url,
        {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
            },
            params  
        }
    ).then((response) => {
        if(response.data.treeView !== undefined){
          
         
          setMapFilename(response.data.extraInfo.mapFilename);
          setMapInherit(response.data.extraInfo.mapInherit === 'Y' ? true : false); 
          let checkedLocations = [];
          let checkedGroups = [];
          let checkedFrames = [];

        if(localStorage.getItem('treeList')===null || localStorage.getItem('prevCountry')!==kCountry+''){
          localStorage.setItem('prevCountry', kCountry);

          //load all selected frames
          let checkedCountries = [];
          let checkedLocations = [];
         
          //eventuale if per evitare di aggiungere il selected
          //metodo da cambiare per renderlo scalabile
          response.data.treeView[0].selected = true;
          checkedCountries.push(response.data.treeView[0].kItem);
         
          if(kCountry==='2'){
            response.data.treeView[0].items.forEach((itemLocation) => {
                  itemLocation.selected = true;
                  checkedLocations.push(itemLocation.kItem)
                    itemLocation.items.forEach((itemGroup) => {

                        if(itemGroup.selected) {

                            checkedGroups.push(itemGroup.groupId);
                        }

                        itemGroup.items.forEach((itemFrame) => {
                          itemFrame.selected = true;
                          checkedFrames.push(itemFrame.kItem);
                        });
                    });
              });
          }else{
            response.data.treeView[0].items.forEach((itemLocation) => {
              itemLocation.selected = true;
              itemLocation.items.forEach((itemGroup) => {
                  if(itemGroup.selected) {
                      checkedGroups.push(itemGroup.groupId);
                  }

                  itemGroup.items.forEach((itemFrame) => {
                    itemFrame.selected = true;
                    checkedFrames.push(itemFrame.kItem);
                  });
              });
            });
          }

          setTreeViewList(response.data.treeView);
          localStorage.setItem("treeList", JSON.stringify(response.data.treeView))
          localStorage.setItem("checkedLocations", JSON.stringify(checkedLocations));
          localStorage.setItem("checkedGroups", JSON.stringify(checkedGroups));
          localStorage.setItem("checkedFrames", JSON.stringify(checkedFrames));
        }else if(forceHardReload){
          checkedLocations=JSON.parse(localStorage.getItem("checkedLocations"))
          checkedGroups=JSON.parse(localStorage.getItem("checkedGroups"));
          checkedFrames=JSON.parse(localStorage.getItem("checkedFrames"));
          let currentTree = JSON.parse(localStorage.getItem("treeList"))
          
              response.data.treeView[0].items.forEach((itemLocation) => {
                    if(checkedLocations.includes(itemLocation.kItem)){
                      itemLocation.selected = true;
                    }else{
                        let matchedLocationFromCurrent = currentTree[0].items.find(currentLocation => currentLocation.id === itemLocation.id)
                        if(matchedLocationFromCurrent.selected === undefined){
                          itemLocation.selected = undefined
                        }else{
                          itemLocation.selected = false
                        }
                    }
                      itemLocation.items.forEach((itemGroup, index) => {
                          if(checkedGroups.includes(itemGroup.groupId)) {
                            itemGroup.selected = true;
                          }else{
                            let matchedLocationFromCurrent = currentTree[0].items.find(currentLocation => currentLocation.id === itemLocation.id)
                            let matchedGroupFromCurrent = matchedLocationFromCurrent.items.find(currentGroup => currentGroup.id === itemGroup.id)

                            if(matchedGroupFromCurrent.selected === undefined){
                              itemGroup.selected = undefined
                            }else{
                              itemGroup.selected = false
                            }
                          }

                          itemGroup.items.forEach((itemFrame) => {
                            if(checkedFrames.includes(itemFrame.kItem)){
                              itemFrame.selected = true;
                            }else{
                              let matchedLocationFromCurrent = currentTree[0].items.find(currentLocation => currentLocation.id === itemLocation.id)
                              let matchedGroupFromCurrent = matchedLocationFromCurrent.items.find(currentGroup => currentGroup.id === itemGroup.id)
                              let matchedFrameFromCurrent = matchedGroupFromCurrent.items.find(currentFrame => currentFrame.id === itemFrame.id)
                              
                              if(matchedFrameFromCurrent.selected === undefined){
                                itemFrame.selected = undefined
                              }else{
                                itemFrame.selected = false
                              }
                            }
                          });

                      });
                });
          
            
                let resultStations = undefined;
                let resultGroups = undefined;
                let resultFrames = undefined;
                let arrSt = [];
                let arrGp = [];
                let arrFr = []
                for(let s = 0; s < response.data.treeView[0].items.length; s++){
                  //stazioni
                  arrSt.push(response.data.treeView[0].items[s].selected);
                  for(let g = 0; g < response.data.treeView[0].items[s].items.length; g++){
                    //gruppi
                    arrGp.push(response.data.treeView[0].items[s].items[g].selected)
                    for(let f = 0; f < response.data.treeView[0].items[s].items[g].items.length; f++){
                      //frames
                      arrFr.push(response.data.treeView[0].items[s].items[g].items[f].selected)
                    }
                  }
                }

                if(arrSt.some(st => st===false || st===undefined)){
                  if(arrSt.some(st => st === undefined)){
                    resultStations = undefined
                  }else{
                      if(arrSt.every(valore => valore===false)){
                        resultStations = false
                      }else{
                        resultStations = undefined
                      }
                  }
                }else{
                  resultStations = true
                }
                if(arrGp.some(gp => gp===false || gp===undefined)){
                  if(arrGp.some(gp => gp === undefined)){
                    resultGroups = undefined
                  }else{
                    if(arrGp.every(valore => valore===false)){
                      resultGroups = false
                    }else{
                      resultGroups = undefined
                    }
                  }
                }else{
                  resultGroups = true
                }
                if(arrFr.some(fr => fr===false || fr===undefined)){
                  if(arrFr.some(fr => fr === undefined)){
                    resultFrames = undefined
                  }else{
                    if(arrFr.every(valore => valore===false)){
                      resultFrames = false
                    }else{
                      resultFrames = undefined
                    }
                  }
                }else{
                  resultFrames = true
                }
                if(resultStations && resultGroups && resultFrames){
                  response.data.treeView[0].selected = true
                }else if(resultStations === false && resultGroups === false && resultFrames === false){
                  response.data.treeView[0].selected = false
                }else{
                  response.data.treeView[0].selected = undefined;
                }
            
                
          setTreeViewList(response.data.treeView);
          localStorage.setItem("treeList", JSON.stringify(response.data.treeView))
          
        }else if( forceReloadFromSearchLocation){
          
          if(kCountry==='2'){
            response.data.treeView[0].items.forEach((itemLocation) => {
                  itemLocation.selected = true;
                  checkedLocations.push(itemLocation.kItem)
                    itemLocation.items.forEach((itemGroup) => {
                        if(itemGroup.selected) {
                          checkedGroups.push(itemGroup.groupId);
                        }
                        itemGroup.items.forEach((itemFrame) => {
                          itemFrame.selected = true;
                          checkedFrames.push(itemFrame.kItem);
                        });
                    });
              });
          }else{
            response.data.treeView[0].items.forEach((itemLocation) => {
              itemLocation.selected = true;
              itemLocation.items.forEach((itemGroup) => {
                  if(itemGroup.selected) {
                    checkedGroups.push(itemGroup.groupId);
                  }

                  itemGroup.items.forEach((itemFrame) => {
                    itemFrame.selected = true;
                    checkedFrames.push(itemFrame.kItem);
                  });
              });
            });
          }
          response.data.treeView[0].selected = true;
          localStorage.setItem("checkedLocations", JSON.stringify(checkedLocations));
          localStorage.setItem("checkedGroups", JSON.stringify(checkedGroups));
          localStorage.setItem("checkedFrames", JSON.stringify(checkedFrames));
          
          setTreeViewList(response.data.treeView);
         
        }else{
          checkedGroups=JSON.parse(localStorage.getItem("checkedGroups"));
          checkedFrames=JSON.parse(localStorage.getItem("checkedFrames"));
          let albero = JSON.parse(localStorage.getItem("treeList"));
          setTreeViewList(albero);
        }

          props.handleChangeCheckedGroups(checkedGroups);
          props.handleChangeCheckedFrames(checkedFrames);

          setReload(!reload);

          props.handleReload(!reload);
          
        }else{
          setTreeViewList([]);
        }

        setLoading(false);

    }, (error) => {
      if(error.response?.status === 401){ //status 401: Unauthorized
        auth.signOut();
      }else{
          console.error(error.response);
      }
    });
  }

  const checkLocationValueAfterChange = (parentEl) => {
    let resultGroups = undefined
    let arrGp = []

    for(let g = 0; g < parentEl.items.length; g++){
      arrGp.push(parentEl.items[g].selected)
    }

    if(arrGp.some(gp => gp===false || gp===undefined)){
      if(arrGp.some(gp => gp === undefined)){
        resultGroups = undefined
      }else{
        if(arrGp.every(valore => valore===false)){
          resultGroups = false
        }else{
          resultGroups = undefined
        }
      }
    }else{
      resultGroups = true
    }
    if(resultGroups){
      parentEl.selected = true
    }else if(resultGroups === false){
      parentEl.selected = false
    }else{
      parentEl.selected = undefined
    }
  }

  const checkCountryValueAfterChange = () => {
    //dopo una qualunque modifica si ricontrolla che tutti gli elementi siano uguali, o ce ne sia almeno uno diverso rispetto al resto, per valorizzare correttamente il country
    let resultStations = undefined;
    let resultGroups = undefined;
    let resultFrames = undefined;
    let arrSt = [];
    let arrGp = [];
    let arrFr = []
    for(let s = 0; s < treeViewList[0].items.length; s++){
      //stazioni
      arrSt.push(treeViewList[0].items[s].selected);
      for(let g = 0; g < treeViewList[0].items[s].items.length; g++){
        //gruppi
        arrGp.push(treeViewList[0].items[s].items[g].selected)
        for(let f = 0; f < treeViewList[0].items[s].items[g].items.length; f++){
          //frames
          arrFr.push(treeViewList[0].items[s].items[g].items[f].selected)
        }
      }
    }

    if(arrSt.some(st => st===false || st===undefined)){
      if(arrSt.some(st => st === undefined)){
        resultStations = undefined
      }else{
          if(arrSt.every(valore => valore===false)){
            resultStations = false
          }else{
            resultStations = undefined
          }
      }
    }else{
      resultStations = true
    }
    if(arrGp.some(gp => gp===false || gp===undefined)){
      if(arrGp.some(gp => gp === undefined)){
        resultGroups = undefined
      }else{
        if(arrGp.every(valore => valore===false)){
          resultGroups = false
        }else{
          resultGroups = undefined
        }
      }
    }else{
      resultGroups = true
    }
    if(arrFr.some(fr => fr===false || fr===undefined)){
      if(arrFr.some(fr => fr === undefined)){
        resultFrames = undefined
      }else{
        if(arrFr.every(valore => valore===false)){
          resultFrames = false
        }else{
          resultFrames = undefined
        }
      }
    }else{
      resultFrames = true
    }
    if(resultStations && resultGroups && resultFrames){
      treeViewList[0].selected = true
    }else if(resultStations === false && resultGroups === false && resultFrames === false){
      treeViewList[0].selected = false
    }else{
      treeViewList[0].selected = undefined;
    }
  }

  const handleSelectionChanged = (e) => {
    if(!e.itemIndex && !e.itemElement){
    
      e.stopPropagation();

    }
    let lsCheckedLocations = [];
    if(localStorage.getItem("checkedLocations") !== null){
      lsCheckedLocations = JSON.parse(localStorage.getItem("checkedLocations"));
    }
    let lsCheckedGroups = [];
    if(localStorage.getItem("checkedGroups") !== null){
      lsCheckedGroups = JSON.parse(localStorage.getItem("checkedGroups"));
    }
    let lsCheckedFrames = [];
    if(localStorage.getItem("checkedFrames") !== null){
      lsCheckedFrames = JSON.parse(localStorage.getItem("checkedFrames"));
    }

    let checkedLocations = [];
    let checkedGroups = [];
    let checkedFrames = [];
    let kCountry = country.split('|')[0];

    let deletedIds = [];
    if(localStorage.getItem("deletedIds") !== null){
      deletedIds = JSON.parse(localStorage.getItem("deletedIds"));
    }

    //a partire dall'elemento cliccato
    let clickedCheckbox = e.target.id !== '' ? e.target.id : e.target.parentNode.id;
    let level = clickedCheckbox.split('_').length;

    //isolo il livello da manipolare, trovo l'elemento, e lo modifico nell'opposto del corrente
    let elToChange = undefined;
    if(level === 1){
      treeViewList[0].selected = !treeViewList[0].selected;
      //tutto quello che esiste sotto il paese viene valorizzato al nuovo valore
      if(treeViewList[0].selected === true){
        for(let s = 0; s < treeViewList[0].items.length; s++){
          //stazioni
          treeViewList[0].items[s].selected = true;
          for(let g = 0; g < treeViewList[0].items[s].items.length; g++){
            //gruppi
            treeViewList[0].items[s].items[g].selected = true;
            for(let f = 0; f < treeViewList[0].items[s].items[g].items.length; f++){
              //frames
              treeViewList[0].items[s].items[g].items[f].selected = true;
            }
          }
        }
      }else{
        for(let s = 0; s < treeViewList[0].items.length; s++){
          //stazioni
          treeViewList[0].items[s].selected = false;
          for(let g = 0; g < treeViewList[0].items[s].items.length; g++){
            //gruppi
            treeViewList[0].items[s].items[g].selected = false;
            for(let f = 0; f < treeViewList[0].items[s].items[g].items.length; f++){
              //frames
              treeViewList[0].items[s].items[g].items[f].selected = false;
            }
          }
        }
      }
    }else if(level === 3){
      elToChange = treeViewList[0].items.find((itemLocation) => 
        itemLocation.id === clickedCheckbox
      )
      //oltre a se stesso cambio i suoi figli
      if(elToChange.selected === true){
        elToChange.selected = false;
        elToChange.items.forEach(figlio => figlio.selected = false);
        //elToChange.items[0].selected = false;
        elToChange.items.forEach(figlio => figlio.items.forEach(nipote => nipote.selected = false));
        //elToChange.items[0].items.forEach(nipote => nipote.selected = false);
      }else{
        elToChange.selected = true;
        elToChange.items.forEach(figlio => figlio.selected = true);
        //elToChange.items[0].selected = true;
        elToChange.items.forEach(figlio => figlio.items.forEach(nipote => nipote.selected = true));
        //elToChange.items[0].items.forEach(nipote => nipote.selected = true);
      }
      checkCountryValueAfterChange();
    }else if(level === 4){
      let stazione = treeViewList[0].items.find(el => 
        clickedCheckbox.split('_').slice(0,level-1).join('_') === el.id
      )
      elToChange = stazione.items.find(gruppo => gruppo.id === clickedCheckbox)

      if(elToChange.selected === true){
        elToChange.selected = false;
        //tutti i figli vengono valorizzati allo stesso valore del gruppo
        elToChange.items.forEach(pannello => pannello.selected = false);
        //la stazione deve riflettere il valore del gruppo
        //if(kCountry !== '1')stazione.selected = false;
      }else{
        elToChange.selected = true;
        elToChange.items.forEach(pannello => pannello.selected = true);
        //if(kCountry !== '1')stazione.selected = true;
      }
      checkLocationValueAfterChange(stazione);
      checkCountryValueAfterChange();
    }else if(level === 5){
      /* elToChange = treeViewList[0].items[0].items[0].items.find((itemFrame) => 
        clickedCheckbox === itemFrame.id
      ) */

      let stazione = treeViewList[0].items.find(el => clickedCheckbox.split('_').slice(0,level-2).join('_') === el.id)
      elToChange = stazione.items[0].items.find((itemFrame) => 
        clickedCheckbox === itemFrame.id
      )
      //check sul livello stazione 
      let fatherElToChange = treeViewList[0].items.find((itemLocations) => {
        //lo splitto, e taglio i livelli che non servono
        return clickedCheckbox.split('_').slice(0,level-2).join('_') === itemLocations.id}
      )
      //gestione del singolo elemento
      
      //controllo i figli (pannelli) e se tutti hanno lo stesso valore, anche lui viene valorizzato a true o false altrimenti entra nello stato square(undefined)
      if(elToChange.selected === true){
        elToChange.selected = false ;
        let arrChildsSelected = fatherElToChange.items[0].items.map(figlio => figlio.selected);
        //se dopo aver inserito un false ed il some viene soddisfatto allora vuol dire che tra i figli (frames) c'è nè almeno uno ancora selezionato.
        if(arrChildsSelected.some(valore => valore === true || valore === undefined )){
          //allo stesso modo di come cambia la stazione, cambia il gruppo
          fatherElToChange.selected = undefined;
          fatherElToChange.items[0].selected = undefined;
        }else{
          //altrimenti si allinea con il valore dei figli
          fatherElToChange.selected = false;
          fatherElToChange.items[0].selected = false;
        }

      }else{
        elToChange.selected = true ;
        let arrChildsSelected = fatherElToChange.items[0].items.map(figlio => figlio.selected);
        //se dopo aver inserito un false ed il some viene soddisfatto allora vuol dire che tra i figli (frames) c'è nè almeno uno ancora selezionato.
        if(arrChildsSelected.some(valore => valore === false || valore === undefined )){
          //allo stesso modo di come cambia la stazione, cambia il gruppo
          fatherElToChange.selected = undefined;
          fatherElToChange.items[0].selected = undefined;
        }else{
          //altrimenti si allinea con il valore dei figli
          fatherElToChange.selected = true;
          fatherElToChange.items[0].selected = true;
        }
      }
      checkCountryValueAfterChange();
    }

    treeViewList[0].items.forEach((itemLocation) => {
      if(itemLocation.selected){
        checkedLocations.push(itemLocation.kItem);
        if(lsCheckedLocations.indexOf(itemLocation.kItem === -1)){
          lsCheckedLocations.push(itemLocation.kItem)
        }
      }else{
        if(lsCheckedLocations.indexOf(itemLocation.kItem) >= 0){
          lsCheckedLocations = lsCheckedLocations.filter(function(value){ 
            return value !== itemLocation.kItem;
          });
        }
      }
      itemLocation.items.forEach((itemGroup) => {
           if(itemGroup.selected) {
              checkedGroups.push(itemGroup.groupId);
              if(lsCheckedGroups.indexOf(itemGroup.groupId) === -1){
                lsCheckedGroups.push(itemGroup.groupId);
              }
          }else{
            if(lsCheckedGroups.indexOf(itemGroup.groupId) >= 0){
              lsCheckedGroups = lsCheckedGroups.filter(function(value){ 
                return value !== itemGroup.groupId;
              });
            }
          }

          itemGroup.items.forEach((itemFrame) => {
            if(itemFrame.selected) {
              checkedFrames.push(itemFrame.kItem);

              if(lsCheckedFrames.indexOf(itemFrame.kItem) === -1){
                lsCheckedFrames.push(itemFrame.kItem);
              }
            }else{
            if(lsCheckedFrames.indexOf(itemFrame.kItem) >= 0){
              lsCheckedFrames = lsCheckedFrames.filter(function(value){ 
                return value !== itemFrame.kItem;
              });
            }
          }
          }); 

      });
    });

    localStorage.setItem("checkedLocations", JSON.stringify(lsCheckedLocations));
    localStorage.setItem("checkedGroups", JSON.stringify(lsCheckedGroups));
    localStorage.setItem("checkedFrames", JSON.stringify(lsCheckedFrames));
    localStorage.setItem("treeList", JSON.stringify(treeViewList))
    props.handleChangeCheckedGroups(checkedGroups);
    props.handleChangeCheckedFrames(checkedFrames);

    setReload(!reload);

    props.handleReload(!reload);
  }

  const toggleMenu = () => {
    props.handleExpand(!expanded);
    setExpanded(!expanded);
  }

  const handleOpenSettings = (value, event) => {
    event.stopPropagation();
    setKItem(value.kItem);
    setKLevel(value.level);

    //setto nel localstorage perchè (inspiegabilmente) sembrano resettarsi nell'interazione con Dropzone (??)
    localStorage.setItem('kItem', value.kItem);
    localStorage.setItem('kLevel', value.level);

    setDLevel(value.text);
    setKContract(value.kContract);
    setMapPhoto(value.mapPhoto);
    setMapFilename(value.mapFilename);
    setMapInherit(value.mapInherit === 'Y' ? true : false);
    setFlagEnabled(value.flagEnabled === 'Y' ? true : false);

    if(kContract !== 0){
      setShowAudience(contracts.find(item => item.KCONTRACT === kContract).SHOW_AUDIENCE === 'Y');
    }

    if(value.dContract !== undefined && value.contractInherit === 'Y'){
      setKContract(0);
      setDNoContract(`${value.dContract} (inherit)`);
    }else{
      setDNoContract(LBL_NO_CONTRACT);
    }

    if(value.level === 1){
      setFlagRejectMsg(value.flagRejectMsg === 'Y');
      setStripeVATActivated(value.stripeVATActivated === 'Y');
    }

    if(value.level === 5 && value.photoEnabled === 'Y'){
      setPanelFilename(`${value.kItem}.jpg`);
    }else{
      setPanelFilename("");
    }
    
    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  const setTreeSettings = (pKContract, flagRejectMsg, flagStripeVat) => { 
    setLoadingDialog(true);

    const url = `${config.API_URL}/tree-settings-set`;

    const params = {
      kLevel,
      kItem,
      kContract: pKContract !== 0 ? pKContract : '',
      flagRejectMsg,
      flagStripeVat
    };

    axios.post(
        url,
        null,
        {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
            },
            params  
        },
    ).then((response) => {

      //update country perchè potrebbe essere cambiuto il flag rejected
      const arrCountry = country.split('|');
      const newCountry = `${arrCountry[0]}|${arrCountry[1]}|${flagRejectMsg}|${flagStripeVat}`;
      setCountry(newCountry);

      loadTreeView();
      setLoadingDialog(false);

    }, (error) => {
        if(error.response.status === 401){
            auth.signOut();
        }else{
            console.error(error.response);
        }
        setLoadingDialog(false);
    });
  }

  const handleChangeContract = (event) => {
    setKContract(event.target.value);

    setTreeSettings(event.target.value, '', '');

    if(event.target.value !== 0){
      setShowAudience(contracts.find(item => item.KCONTRACT === event.target.value).SHOW_AUDIENCE === 'Y');
    }
  };

  const handleChangeFlagRejectMsg = (event) => {
    setFlagRejectMsg(event.target.checked);

    setTreeSettings('', event.target.checked ? 'Y' : 'N', '');
  };

  const handleChangeStripeVATActivated = (event) => {
    setStripeVATActivated(event.target.checked);

    setTreeSettings('', '', event.target.checked ? 'Y' : 'N');
  };

  const handleChangeShowAudience = (event) => {
    const pShowAudience = event.target.checked

    setShowAudience(pShowAudience);

    //update contract attributes
    const url = `${config.API_URL}/contract-mod`;

    const params = {
      kContract,
      showAudience: pShowAudience ? 'Y' : 'N'
    };

    axios.post(
        url,
        null,
        {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
            },
            params  
        },
    ).then((response) => {

      loadContracts();

    }, (error) => {
        if(error.response.status === 401){
            auth.signOut();
        }else{
            console.error(error.response);
        }
    });
  };

  const handleChangeMapPhoto = (event) => {
    setMapPhoto(event.target.value);

    const mapPhoto = event.target.value;
    updateMapPhoto(mapPhoto, "");
  }
  

  const updateMapPhoto = (mapPhoto, mapFilename, mapFilenameRemove = false) => {

    setLoadingDialog(true);

    const url = `${config.API_URL}/map-photo-set`;

    const localKCountry = country.split('|')[0];
    const localKLevel = localStorage.getItem('kLevel');
    const localKItem = localStorage.getItem('kItem');

    const params = {
      kLevel: localKLevel,
      kItem: localKItem,
      mapPhoto,
      mapFilename,
      mapFilenameRemove: mapFilenameRemove ? 'Y' : 'N',
    };

    axios.post(
        url,
        null,
        {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
            },
            params  
        },
    ).then((response) => {

      loadTreeView(localKCountry);
      setLoadingDialog(false);

      if(mapFilenameRemove){
        setPanelFilename("");
      }
      
    }, (error) => {
        if(error.response.status === 401){
            auth.signOut();
        }else{
            console.error(error.response);
        }
        setLoadingDialog(false);
    });

  }

  const handleChangeLocationFilter = (event) => {
    setLocationFilter(event.target.value);
  };

  const handleAntChange = (event) => {
    setFlagEnabled(event.target.checked);
    setTreeFlagEnabled(event.target.checked);
  }

  const setTreeFlagEnabled = (pFlagEnabled) => {
    setLoadingDialog(true);

    const url = `${config.API_URL}/tree-flag-enabled-set`;

    const params = {
      kLevel,
      kItem,
      flagEnabled: pFlagEnabled ? 'Y' : 'N'
    };

    axios.post(
        url,
        null,
        {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
            },
            params  
        },
    ).then((response) => {

      loadTreeView(true);
      setLoadingDialog(false);

    }, (error) => {
        if(error.response.status === 401){
            auth.signOut();
        }else{
            console.error(error.response);
        }
        setLoadingDialog(false);
    });
  }


  // *** dropzone ***
  const onDrop = useCallback((acceptedFiles, args) => {

    const kLevel = parseInt(args[0]);
    const kItem = parseInt(args[1]);

    setLoadingDialog(true);

    acceptedFiles.forEach((file) => {

      if(file.size > 1048576){
        console.error('file size is more than 1Mb');
        setErrImgSize(labels[`error-upload-image-size`]);
        setLoadingDialog(false);
        return;
      }

      let ext = "jpg";
      if(file.type === "image/png"){
        ext = "png";
      }

      let fileName = "";
      let key = "";

      if(kLevel === 3 || kLevel === 4){
        fileName = `${uuidv4()}.${ext}`;
        key = `map-photos/${fileName}`;
      }else if(kLevel === 5){
        fileName = `${kItem}.${ext}`;
        key = `panel-photos/${fileName}`;        
      }

      let url = `${config.API_URL}/upload-signed-url`;

      let params = {
        key,
        type: file.type
      };

      axios.post(
        url,
        null,
        {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
            },
            params  
        },
      ).then(res => {

          const url = res.data.url;

          const options = {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          };

          if(kLevel === 3 || kLevel === 4){
            axios.put(url, file, options).then(res => {
              updateMapPhoto('', fileName);
              setLoadingDialog(false);
            });
          }else if(kLevel === 5){
            axios.put(url, file, options).then(res => {
              setPanelFilename(fileName);
              updateMapPhoto("", "", false);
              setLoadingDialog(false);
            });
          }
          setErrImgSize("");
      }).catch(err => {
          console.error(err);
          setLoadingDialog(false);
      });

    });

  }, []);  

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone(   { onDrop: (acceptedFiles, args) => onDrop(acceptedFiles, [kLevel, kItem])  , accept: kLevel === 5 ? 'image/jpeg' : 'image/jpeg,image/png',  multiple: false});

  const dropZonestyle = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);
  
  const removeFile = () => {
    updateMapPhoto("", "", true);
  }


  // *** end dropzone ***

  const iconToRender = (props) => {
    //level 3 -> station
    //level 4 -> panels label
    //level 5 -> single panels
    const check = <CheckIcon sx={{color:'#337ab7'}}></CheckIcon>;
    const square = <SquareRoundedIcon className='force-square-shape'></SquareRoundedIcon>;
    let arrKeys = Object.keys(props);
    
    if(props.level === 3){
      if(props.selected===true){
        return check;
      }else if(props.selected===false){
        return null
      }else if(arrKeys.includes('selected')){
        return square
      }else{
        return null
      }
    }else if(props.level === 4 || props.level===1){
      if(props.selected===true){
        return check;
      }else if(props.selected===false){
        return null;
      }else{
        return square;
      }
    }else if(props.level === 5){
      if(props.selected){return check}else{return null}
    }
  }
  
  const ComplexTreeItem = (props) => {
    let tooltip = "Settings";
    
    if(props.kContract !== 0){
      if(props.contractInherit === 'Y'){
        tooltip = `${tooltip} - Contract: ${props.dContract} (inherit)`;
      }else{
        tooltip = `${tooltip} - Contract: ${props.dContract}`;
      }
    }else if(props.level < 5){
      tooltip = `${tooltip} - No Contract`;    
    }

    if(props.level === 3 || props.level === 4){
      if(props.mapFilename !== "" && props.mapPhoto === 'P'){
        if(props.mapInherit === 'Y'){
          tooltip = `${tooltip}, Image: Photo (inherit)`;
        }else{
          tooltip = `${tooltip}, Image: Photo`;    
        }
      }else if(props.mapPhoto === 'M'){
        tooltip = `${tooltip}, Image: Map`;
      }else{
        tooltip = `${tooltip}, Image: None`;
      }
    }

    return (<>
    <div className='tree-item-wrap' style={props.level===5&&country.split('|')[0]==='1'?{height: '32px'}:{}} onClick={props.onClick}>
      {props.level===5&&country.split('|')[0]==='1' ? '' 
      : 
      <div className='side-checkbox-container'>
        <div className={`side-checkbox-span ${props.selected}`} id={props.id} onClick={(e)=>handleSelectionChanged(e)}>
          {iconToRender(props)}
        </div>
      </div>}
      <div className={props.flagEnabledResult==='Y' ? "label-group" : "label-group disabled"}>{props.text}</div>
    </div>    
    <div onClick={props.onClick}>
      <Tooltip title={tooltip}>
        <div className="ico-settings">
          <SettingsIcon sx={{fontSize:'18px'}}/>
        </div>
      </Tooltip>
    </div>
    </>)
  }

  const renderTreeElements = () => {
    return treeViewList.map(paese => 
      <TreeItem nodeId={paese.id} label={<ComplexTreeItem {...paese} onClick={(event) => handleOpenSettings(paese,event)}></ComplexTreeItem>} className='flexed-tree-item'>
        {paese.items.map(stazione =>
          <TreeItem nodeId={stazione.id} label={<ComplexTreeItem {...stazione} onClick={(event) => handleOpenSettings(stazione,event)}></ComplexTreeItem>}>
            {stazione.items.map(pannello => 
              <TreeItem nodeId={pannello.id} label={<ComplexTreeItem {...pannello} onClick={(event) => handleOpenSettings(pannello,event)}></ComplexTreeItem>}>
                {pannello.items.map(faccia => 
                  <div className='tree-item-wrap-super' onClick={(event) => handleOpenSettings(faccia,event)}><img style={faccia.level===5&&country.split('|')[0]==='1'?{paddingLeft: '21px'}:{}} src="assets/images/screen-ico.png" className='img-pnl' alt="" />
                  <TreeItem nodeId={faccia.id} label={<ComplexTreeItem {...faccia}></ComplexTreeItem>} className='no-pad'/></div>
                )}
              </TreeItem>
            )}
          </TreeItem>)}
      </TreeItem>
    )
    
    /* (<><TreeItem nodeId="1" label="Applications">
    <TreeItem nodeId="2" label="Calendar" />
  </TreeItem>
  <TreeItem nodeId="5" label="Documents">
    <TreeItem nodeId="10" label="OSS" />
    <TreeItem nodeId="6" label="MUI">
      <TreeItem nodeId="8" label="index.js" />
    </TreeItem>
  </TreeItem></>) */
  }

  return (
      <div className={expanded ? "side-nav expanded" : "side-nav"}>
        {loading && (<div className="circular-progress"><CircularProgress /></div>)}

        <Dialog
            open={openSettings}
            onClose={handleCloseSettings}
            PaperComponent={PaperComponent}
        >
            <BootstrapDialogTitle onClose={handleCloseSettings}>
              Settings of {dLevel}
            </BootstrapDialogTitle>

            <DialogContent style={{paddingBottom: 0}}>
                <div className="dialog-settings">
                  {loadingDialog && (<div className="progress"><CircularProgress /></div>)}

                  {(kLevel === 4 || kLevel === 5) && (
                    <>
                      <div className="switch-activation">
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>Disabled</Typography>
                          <AntSwitch checked={flagEnabled} inputProps={{ 'aria-label': 'ant design' }} onChange={handleAntChange} />
                          <Typography>Enabled</Typography>
                        </Stack>                      
                      </div>

                      <Divider variant="middle" style={{margin: "15px 0"}} />

                      {kLevel === 5 && (
                        <section className="dp-container" style={{marginTop: 30}}>
                          <div {...getRootProps({style: dropZonestyle})}>
                            <input {...getInputProps()} />

                            {panelFilename !== "" && (
                              <p>{panelFilename}</p>
                            )}

                            {panelFilename === "" && (
                            <p>Select an image (jpg or png accepted)</p>
                            )}
                          </div>
                          
                          {panelFilename !== "" && (
                            <p className="remove-file"><Tooltip title="Delete Photo"><CloseIcon onClick={removeFile} /></Tooltip></p>
                          )}

                        </section>         
                        )}  


                    </>
                  )}

                  {kLevel < 5 && (
                    <>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="open-select-contract">Contract</InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          value={kContract}
                          label="Contract"
                          onChange={handleChangeContract}
                          disabled={kLevel === 4}
                        >
                          <MenuItem value={0}>{dNoContract}</MenuItem>
                          {contracts.map((contract) => (
                            <MenuItem value={contract.KCONTRACT}>{contract.DCONTRACT}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                            
                      <div className='contract-attr-list'>  
                        {kContract !== 0 && (
                          <div style={{marginTop: 10}}>
                            <span>Show Audience:</span>
                            <span style={{marginLeft: 10}}>
                              <Switch
                                size="small"
                                checked={showAudience}
                                onChange={handleChangeShowAudience}
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                </div>

                {(kLevel === 3 || kLevel === 4) && (
                  <>
                    <Divider variant="middle" style={{margin: "15px 0"}} />
                    
                    <div className="dialog-settings">
                    
                      <div className="map-image-cnt">
                        <div>
                          <FormControl
                            value={mapPhoto}
                            onChange={handleChangeMapPhoto} 
                          >
                            <FormLabel>Image/Map</FormLabel>
                            <RadioGroup>
                              <FormControlLabel value="M" control={<Radio />} label="Map" checked={mapPhoto === 'M'} />
                              <FormControlLabel value="P" control={<Radio />} label="Photo" checked={mapPhoto === 'P'}/>
                              <FormControlLabel value="N" control={<Radio />} label="None" checked={mapPhoto === 'N'} />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        {mapPhoto === 'P' && (
                        <section className="dp-container">
                          <div {...getRootProps({style: dropZonestyle})}>
                            <input {...getInputProps()} />

                            {mapFilename !== "" && (
                              <p>{mapFilename} {mapInherit ? "(inherit)" : ""}</p>
                            )}

                            {mapFilename === "" && (
                            <p>Select an image (jpg or png accepted)</p>
                            )}
                          </div>
                          
                          {mapFilename !== "" && !mapInherit && (
                            <p className="remove-file"><Tooltip title="Delete Photo"><CloseIcon onClick={removeFile} /></Tooltip></p>
                          )}

                        </section>         
                        )}           
                        
                      </div>
                    </div>
                  </>
                )}

              {(kLevel === 1) && (
                  <>
                    <div style={{marginTop: 20, marginLeft: 10}}>
                      <span>Rejection message:</span>
                      <span style={{marginLeft: 10}}>
                        <Switch
                          size="small"
                          checked={flagRejectMsg}
                          onChange={handleChangeFlagRejectMsg}
                        />
                      </span>
                    </div>

                    <div style={{marginTop: 20, marginLeft: 10}}>
                      <span>Stripe VAT activated:</span>
                      <span style={{marginLeft: 10}}>
                        <Switch
                          size="small"
                          checked={stripeVATActivated}
                          onChange={handleChangeStripeVATActivated}
                        />
                      </span>
                    </div>
                  </>
              )}
              <div className='upload-img-err-size'>{errImgSize !== "" && errImgSize}</div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCloseSettings}>Close</Button>
            </DialogActions>    
        </Dialog>

        <div className="cnt-close">
          <div>
            <IconButton aria-label="delete" size="large" onClick={toggleMenu}>
              {expanded ? <CloseIcon fontSize="inherit" /> : <MenuIcon fontSize="inherit" />}
            </IconButton>
          </div>
        </div>

        <Card className="card-selection">
          <div className="location-filter">
            <TextField
              id="locationFilter"
              label="Filter by Location"
              value={locationFilter}
              onChange={handleChangeLocationFilter}
              variant="standard" 
              size="small"
            />
          </div>
          <div className="tree-view">
          <TreeView
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpanded={[2,1]}
            defaultExpandIcon={<ArrowRightIcon />}
            sx={{ height: treeViewHeight, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
          >
            {treeViewList.length ? renderTreeElements() : 'No data to display'}
          </TreeView>
               {/* <Tree
                  id="tree-view-filter"
                  items={treeViewList}
                  height={treeViewHeight}
                  showCheckBoxesMode="normal"
                  onItemSelectionChanged={(e) => handleSelectionChanged(e)}
                  itemRender={renderTreeViewItem}
              /> */}
          </div>
        </Card>
                
      </div>
  )
}

export default SideNav;

const environment = {
    dev: {
        API_URL: 'https://backend.mediaone.systrategy.com/cinv',
        //API_URL: 'https://zsiule8z23.execute-api.eu-west-3.amazonaws.com',
        NEW_USER_TEMP_PASSWORD: 'GoliveGateway01#',
        //CONTENT_DISTRIBUTION_URL: 'https://clearchannel-contents.golive.systrategy.com/dev',
        CONTENT_DISTRIBUTION_URL: 'https://attachments.cinv.systrategy.com',
        REACT_APP_USERPOOL_ID: 'eu-west-3_cbE05r2GY',
        REACT_APP_CLIENT_ID: '143r0o1bre48lbspj0hihsh319',
        REACT_APP_ENDPOINT: 'https://clearchannel-golive.auth.eu-west-3.amazoncognito.com',        
        RECAPTCHA_PUBLIC_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        PWD_DAYS_LIMIT: 180,
        REFRESH_TOKEN_INTERVAL: 1800000,
        DAY_IN_SECONDS: 86400,
        FORCE_MFA_TOTP: false
    },
    test: {
        API_URL: 'https://clearchannel.api.systrategy.com/test-golive-admin',
        NEW_USER_TEMP_PASSWORD: 'GoliveGateway01#',
        CONTENT_DISTRIBUTION_URL: 'https://clearchannel-contents.golive.systrategy.com/test',
        REACT_APP_USERPOOL_ID: 'eu-west-3_uNgjnmKR3',
        REACT_APP_CLIENT_ID: '1o5auoh9754t3tesctck6l9c6r',
        REACT_APP_ENDPOINT: 'https://clearchannel-golive.auth.eu-west-3.amazoncognito.com',      
        RECAPTCHA_PUBLIC_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        PWD_DAYS_LIMIT: 180,
        REFRESH_TOKEN_INTERVAL: 1800000,
        DAY_IN_SECONDS: 86400,
        FORCE_MFA_TOTP: true
    },
    prod: {
        API_URL: 'https://clearchannel.api.systrategy.com/golive-admin',
        NEW_USER_TEMP_PASSWORD: 'Launchpad&23!001',
        CONTENT_DISTRIBUTION_URL: 'https://clearchannel-contents.golive.systrategy.com/prod',
        REACT_APP_USERPOOL_ID: 'eu-west-3_cDK3szwjl',
        REACT_APP_CLIENT_ID: '62061rjjpn3113g5q6vksgl987',
        REACT_APP_ENDPOINT: 'https://clearchannel-golive.auth.eu-west-3.amazoncognito.com',
        RECAPTCHA_PUBLIC_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        PWD_DAYS_LIMIT: 180,
        REFRESH_TOKEN_INTERVAL: 1800000,
        DAY_IN_SECONDS: 86400,
        FORCE_MFA_TOTP: true
    }
};

export default environment[ process.env.REACT_APP_ENV || 'prod' ];
import React, { useState, useEffect, useContext, useRef } from 'react'
import "./reporting.css";
import config from '../../config';
import { alpha, styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../contexts/authContext';
import { Country } from '../../App';
import axios from 'axios';
import CommonUtilities from "../../components/CommonUtilities";
import labelData from '../../labels.json';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/lab/Pagination';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker from '@mui/lab/DateRangePicker';
import addWeeks from 'date-fns/addWeeks';
import TextField from '@mui/material/TextField';
import Availability from './components/Availability';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

const groupLabels = [
    {key: "group.be.eurostar", value: "Thalys-Eurostar area"},
    {key: "group.be.level0.entry", value: "Level 0 Entry"},
    {key: "group.be.level0.exit", value: "Level 0 Exit"},
    {key: "group.be.level0.hall", value: "Level 0 Hall"},
    {key: "group.be.level1", value: "Level 1"},
    {key: "group.be.entry", value: "Entrance"},
    {key: "group.be.international-trains", value: "International Trains"},
    {key: "group.be.platform", value: "Platform"},
    {key: "group.be.level3", value: "Level 3"},
    {key: "group.be.level0", value: "Level 0"},
    {key: "group.be.level-1", value: "Level -1"}
];

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const DateRangeTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme }) => ({
    '& .MuiFilledInput-root': {
      border: '1px solid #e2e2e1',
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

const tableMessages = {
  noData: 'No Slot available',
};

const leftColumns = ['ASSET'];

const defaultStartDate = new Date(new Date().setDate(new Date().getDate() + 3));

const Reporting = (props) =>{
    const history = useHistory();

    const authContext = useContext(AuthContext);
    const [country, setCountry] = useContext(Country);    

    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const labels = labelData.labels;
    const [snackMsg, setSnackMsg] = useState("");
    const [snackSeverity, setSnackSeverity] = useState("");
    const [snackOpen, setSnackOpen] = useState(false)
    const [open, setOpen] = useState(false);
    const [slotType, setSlotType] =  useState(2);
    const [quantitaSlots, setQuantitaSlots] =  useState(1);
    const [columns, setColumns] =  useState([]);
    const [columnBands, setColumnBands] =  useState([]);
    const [rows, setRows] =  useState([]);
    const [tableColumnExtensions, setTableColumnExtensions] =  useState([]);
    const [gridHeight, setGridHeight] = useState(0);
    const [openPreview, setOpenPreview] = useState(false);
    const [previewTitle, setPreviewTitle] = useState("");
    const [previewKCampaign, setPreviewKCampaign] = useState(undefined);
    const [previewDCampaign, setPreviewDCampaign] = useState("");
    const [previewWidth, setPreviewWidth] = useState(230);
    const [previewHeight, setPreviewHeight] = useState(400);
    const [signedUrl, setSignedUrl] = useState("");
    const [page, setPage] = useState(1);
    const [pageUI, setPageUI] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [startDate, setStartDate] = useState(undefined);    
    const [endDate, setEndDate] = useState(undefined);
    const [dateRange, setDateRange] = useState([undefined, undefined]);    
    const [isMounted, setIsMounted] = useState(false);
    const videoRef = useRef();    

    const kCountry = parseInt(country.split('|')[0]);
    const countryDetailLevel = country.split('|')[1];

    const updateDimensions = async() => {  
        setGridHeight(window.innerHeight - 193);
    };

    useEffect(() =>  {   
        window.addEventListener('resize', updateDimensions);
        updateDimensions();

        let initStartDate = undefined;
        let initEndDate = undefined;

        if(localStorage.getItem('startDate') !== null && localStorage.getItem('endDate') !== null ){
            initStartDate = new Date(localStorage.getItem('startDate'));
            initEndDate = new Date(localStorage.getItem('endDate'));
        }else{
            initStartDate = defaultStartDate;
            initEndDate = defaultStartDate;
        }

        setDateRange([initStartDate, initEndDate]);

        setStartDate(initStartDate);
        setEndDate(initEndDate);

    }, []);

    useEffect(() => {
        loadGrid(1);
    }, [props.reload]);

    useEffect(() => {
        if(isMounted){
            let startDate = dateRange[0];
            let endDate = dateRange[1];

            if(startDate !== null && endDate !== null ){
                setStartDate(startDate);
                setEndDate(endDate);
                localStorage.setItem('startDate', startDate);
                localStorage.setItem('endDate', endDate);

                loadGrid(1);
            }
        }
    }, [dateRange]);

    useEffect(() => {
        if(isMounted){
            loadGrid();
        }
    }, [page]);

    useEffect(() => {    
        videoRef.current?.load(); 
      }, [signedUrl]);

    const loadGrid = (pPage = undefined) => {
        setLoading(true);

        setRows([]);

        const url = `${config.API_URL}/slots`;
        //const url = `https://zsiule8z23.execute-api.eu-west-3.amazonaws.com/slots`

        const dateFrom = CommonUtilities.getStringFromDate(dateRange[0]);
        const dateTo = CommonUtilities.getStringFromDate(dateRange[1]);

        const params = {
            groupingLevel: countryDetailLevel,
            listaKFace: props.listaKFace.toString(),
            dateFrom,
            dateTo,
            slotType: 2,
            kCountry,
            page: pPage !== undefined ? pPage : page
        };

        setPageUI(pPage !== undefined ? pPage : page);

        axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            }
        ).then((response) => {
            setColumns([])
            setColumns(response.data.columns);
            setColumnBands(response.data.columnBands);
            setRows(response.data.rows);
            setTableColumnExtensions(response.data.tableColumnExtensions);
            setPageCount(response.data.pageCount);
            setLoading(false);
            
            setIsMounted(true);
            
        }, (error) => {
            if(error.response?.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }
        });

    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeSlotType = (slotType) => {
        setSlotType(slotType);
    }

    const handleChangeQuantitaSlots = (quantitaSlots) => {
        setQuantitaSlots(quantitaSlots);
    }

    const handleChangeDateRange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
      }

    const handleSetSlots = () => {
        setOpen(false);
        setLoading(true);
        
        const url = `${config.API_URL}/slots-set`;

        const params = {
            kCountry,
            listaKFace: props.listaKFace.toString(),
            fromDate: CommonUtilities.getStringFromDate(startDate),
            toDate: CommonUtilities.getStringFromDate(endDate),
            slotType,
            quantitaSlots
        };

        axios.post(
            url,
            params,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },  
            },

        ).then((response) => {

            setSnackMsg(labels["message-success"]);
            setSnackSeverity("success");
            setSnackOpen(true);

            loadGrid();

        }, (error) => {
            if(error.response?.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            
                const errorCode = error.response.data;

                setSnackMsg(labels[`error-availabilities-set${errorCode}`]);
                setSnackSeverity("error");
                setSnackOpen(true);

                setLoading(false);
            }
        });
    }

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    const handleOpenPreview = (item) => {
        setLoading(true);

        setPreviewTitle("Advertiser Slot (booked)")
        setPreviewWidth(230);
        setPreviewHeight(400);
        setPreviewKCampaign(item.kCampaign);
        setPreviewDCampaign(item.dCampaign);

        if(item.rendered === 'Y'){

            const url = `${config.API_URL}/content`;
        
            const params = {
                contentUrl: `videos/portrait/${item.creativeId}.mp4`
            };
        
            axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },
                    params  
                }
            ).then((response) => {
        
                setSignedUrl(response.data);
        
            }, (error) => {
                if(error.response?.status === 401){ //status 401: Unauthorized
                    auth.signOut();
                }else{
                    console.error(error.response);
                }
                setLoading(false);
            });
        }else{
            setSignedUrl("");
        }

        setOpenPreview(true);
        setLoading(false);
    };

    const handleClosePreview = () => {
        setOpenPreview(false);
    };

    const handleClickRefresh = () => {
        loadGrid();
    };

    const createFirstHeadColumn = () => {
        return <>
            <TableCell rowSpan={2} className="assetHeadCell">{countryDetailLevel === 'F' ? 'Panel' : 'Panel Group'}</TableCell>
            {(columnBands.map( (column, index) => {
                return (<TableCell 
                            key={index} 
                            align="center"
                            colSpan={kCountry === 1 ? 2 : 3}
                            >
                            {column.title}
                        </TableCell>)
            }))}
        </>
    }

    const createSecondHeadColumn = () => {
        return <>
            {(columns.slice(1).map( (column, index) => {
                return (<TableCell 
                            key={index} 
                            align="center"
                            className="slot-cell"
                            >
                            {column.title}
                            
                        </TableCell>)
            }))}
        </>
    }

    const CustomCells = () =>{
        if(rows.length !== 0){
            return (rows.map( row => {
                return <TableRow>
                    {Object.keys(row).sort((a,b)=>a < b ? -1 : ( a > b ? 1 : 0 )).map( key => {
                        if (key==='ASSET'){
                            let [province, location, groupDesc, groupFlagEnabled, digitalId, frameFlagEnabled] = (row.ASSET || []).split('|');
                            return <TableCell className="assetRowCell">
                                <div>
                                    {location}
                                    <br />

                                    <span className={groupFlagEnabled === 'Y' ? 'lbl-group' : 'lbl-group disabled'}>{groupDesc}</span>

                                    {false ? <span style={{color: "red"}}>This screen/package has been deactivated and is currently not bookable for users.</span> : ""}

                                    {countryDetailLevel === 'F' && (
                                        <>
                                            <br />
                                            <span className={groupFlagEnabled === 'Y' && frameFlagEnabled === 'Y' ? 'lbl-frame' : 'lbl-frame disabled'}>{digitalId}</span>
                                        </>
                                    )}

                                </div>
                            </TableCell>
                        }else{
                            const item = row[key];
                            return <TableCell className="slot-cell">
                                {item.map(singleItem => {
                                    let thumbSrc = 'https://via.placeholder.com/80/0c86c4/0c86c4';
                                    if(singleItem.rendered === 'Y'){
                                        thumbSrc = `${config.CONTENT_DISTRIBUTION_URL}/thumbs/${singleItem.creativeId}.png`;
                                    }
                                return <div>
                                    {
                                        singleItem.booked
                                            ?
                                            <div className="slot-booked adv" title="Advertiser Slot (booked)">
                                                <div className="slot-rotation">
                                                    {
                                                        <div>
                                                            <img className={singleItem.rendered === 'Y' ? "slot-thumb" : "slot-thumb placeholder"} src={thumbSrc} alt="" onClick={() => handleOpenPreview(singleItem)} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            singleItem.slotType !== ""
                                                    ?
                                                    <div className="chip-adv" title="Advertiser Slot (available)"></div>
                                                    :
                                                    <div />
                                    }
                                </div>})}
                            </TableCell>
                        }
                    })}
                </TableRow>
            }))
    }else{
        return <TableRow>
                    <TableCell 
                        colSpan={columns.length+1} 
                        sx={{textAlign:'center !important'}}
                        >
                        <div className='noSlot'>No Slot available</div>
                    </TableCell>
                </TableRow>;
    }
    }

    const handlePageChange = (event, value) => {
        setPage(value);
        setPageUI(value);
    };
    
    function getWeeksAfter(date, amount) {
        return date ? addWeeks(date, amount) : undefined;
    }

    const linkToCampaign = `/#/campaign/${previewKCampaign}`;
    
    return (
        <div className="reporting">
            {loading && (<div className="circular-progress"><CircularProgress /></div>)}

            <Snackbar open={snackOpen} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical: 'top', horizontal:'center'}}>
                <Alert onClose={handleCloseSnack} severity={snackSeverity}>
                    {snackMsg}
                </Alert>
            </Snackbar>

            <Dialog
                open={open}
                onClose={handleClose}   
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {countryDetailLevel === 'F' && (
                        <>
                            Set Slots ({(props.listaKFace || '').length} Panel{(props.listaKFace || '').length > 1 ? 's' : ''} selected)
                        </>
                    )}
                    {countryDetailLevel === 'G' && (
                        <>
                            Set Slots ({(props.listaKGroup || '').length} Panel Group{(props.listaKGroup || '').length > 1 ? 's' : ''} selected)
                        </>
                    )}                    
                </DialogTitle>
                <DialogContent>
                    <Availability startDate={startDate} 
                                  endDate={endDate}
                                  handleChangeSlotType={(slotType) => handleChangeSlotType(slotType)}  
                                  handleChangeDateRange={(starDate, endDate) => handleChangeDateRange(starDate, endDate)}  
                                  handleChangeQuantitaSlots={(quantitaSlots) => handleChangeQuantitaSlots(quantitaSlots)} 
                                
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" autoFocus onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={handleSetSlots}>Set Slots</Button>
                </DialogActions>    
            </Dialog>


            <Dialog
                    open={openPreview}
                    onClose={handleClosePreview}
                    PaperComponent={PaperComponent} 
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-campaign-dett">
                        {previewTitle}
                    </DialogTitle>
                    <DialogContent>
                        {signedUrl !== "" && (
                            <video ref={videoRef} controls autoplay="true" width={previewWidth} height={previewHeight}>
                                <source src={signedUrl} type="video/mp4" />
                            </video>
                        )}

                        <div className="reporting-campaig-link">
                            Campaign:<br /><a href={linkToCampaign} target="_blank">{previewDCampaign}</a>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClosePreview}>Close</Button>
                    </DialogActions>    
                </Dialog>

            <div className={!props.sideNavExpanded ? "extended" : "normal"}>
                <Card>
                    <CardHeader
                        title="Slot Booking"
                        action={
                            [
                                <Button variant="contained" className="btn-action" onClick={handleClickOpen} disabled={props.listaKFace.length === 0}>Set Slots</Button>,
                                <Button variant="contained" color="success" disabled className="btn-action">Booking</Button>
                            ]
                        }      
                        style={{minWidth: 680}}          
                    />
                    <CardContent className="card-booking">

                        <div className="date-range">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateRangePicker
                                startText="Start date"
                                endText="End date"
                                inputFormat="dd/MM/yyyy"
                                maxDate={getWeeksAfter(dateRange[0], 4)}
                                value={dateRange}
                                onChange={(newValue) => {
                                    setDateRange(newValue);
                                }}
                                renderInput={(startProps, endProps) => (
                                    <React.Fragment>
                                        <DateRangeTextField {...startProps} variant="filled" size="small" style={{width: 120, marginRight: 20}} />
                                        <DateRangeTextField {...endProps} variant="filled" size="small" style={{width: 120}} />
                                    </React.Fragment>
                                )}
                                />
                            </LocalizationProvider>
                        </div>

                        <div className="refresh">
                            <Tooltip title="Reload">
                                <IconButton color="primary" aria-label="refresh" onClick={handleClickRefresh}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        </div>

                        
                        <Paper className="grid-cnt" style={{height: gridHeight}}>
                            {rows.length > 0 && (
                                <Table style={{width: (250 + 40*columns.length)}}>
                                    <TableHead>
                                        <TableRow className='daysHeader'>
                                            {createFirstHeadColumn()}
                                        </TableRow>
                                        <TableRow className='daypartsHeader'>
                                            {createSecondHeadColumn()}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {CustomCells()}
                                    </TableBody>
                                </Table>
                            )}
                        </Paper>
                        
                    </CardContent>

                    <div className="pager">
                        <div>
                            {pageCount > 0 && (
                                <Pagination count={pageCount} page={pageUI} onChange={handlePageChange} />
                            )}
                        </div>
                    </div>

                </Card>
            </div>
        </div>
    )
}

export default Reporting

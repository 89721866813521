import React, { useState, useEffect, useContext } from 'react'
import "./Availability.css";

import CommonUtilities from "../../../components/CommonUtilities";

import Button from '@mui/material/Button';
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import addMonths from 'date-fns/addMonths';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const Availability = (props) =>{

    const [slotType, setSlotType] =  useState(2);
    const [quantitaSlots, setQuantitaSlots] =  useState(1);
    const [dateRange, setDateRange] = useState([props.startDate, props.endDate]);

    useEffect(() =>  {      
        props.handleChangeSlotType(slotType);
        //(props.startDate);
        //console.log(props.endDate);
        
    }, [slotType]);

    useEffect(() =>  {      
        props.handleChangeQuantitaSlots(quantitaSlots);
    }, [quantitaSlots]);

    useEffect(() =>  {      
        let startDate = dateRange[0];
        let endDate = dateRange[1];
    
        if(startDate !== null && endDate !== null ){    
    
          const maxDate = getMaxDate(startDate, 12);
    
          if(endDate > maxDate){
            endDate = maxDate;
            setDateRange([startDate, endDate]);
          }
    
          props.handleChangeDateRange(startDate, endDate);
        }
    
      }, [dateRange]);

    const handleChangeSlotType = (event) => {
        setSlotType(event.target.value);
    };

    const handleChangeQuantitaSlot = (event) => {
        setQuantitaSlots(event.target.value);
    };    

    function getMaxDate(date, amount) {
        return date ? addMonths(date, amount) : undefined;
      }

/*
    const toggleWarningBooked = (event) => {
        this.setState({
            warningBookedModal: !this.state.warningBookedModal
        });
    }

    const forceWarningBooked = (event) => {
        this.setState({
            warningBookedModal: !this.state.warningBookedModal
        });
        this.setAvailabilities(true);
    }
*/
    return (
        <div className="availability">
            {/*
            <Modal isOpen={warningBookedModal} toggle={this.toggleWarningBooked}>
                <ModalHeader>Booking found!</ModalHeader>
                <ModalBody>
                    Booking are present in the selected date period.<br />
                    Do you want to proceed only on available Slots?
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="button-blue" onClick={this.forceWarningBooked}>yes</Button>
                    <Button variant="contained" className="button-gray" onClick={this.toggleWarningBooked}>Cancel</Button>
                </ModalFooter>
            </Modal>
            */}

            <div className="row" style={{marginBottom:20}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                        disablePast
                        startText="Start date"
                        endText="End date"
                        maxDate={getMaxDate(dateRange[0], 12)}
                        value={dateRange}
                        onChange={(newValue) => {
                            setDateRange(newValue);
                        }}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                            <TextField {...startProps} variant="standard" size="small" style={{width: 120, marginRight: 20}} />
                            <TextField {...endProps} variant="standard" size="small" style={{width: 120}} />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </div>

            <div className="row" style={{marginBottom:20}}>
                <div style={{maxWidth:250}}>
                    <FormControl style={{width: "100%"}}>
                        <InputLabel variant="standard">Slot Type</InputLabel>

                        <Select
                            variant="standard"
                            value={slotType}
                            onChange={handleChangeSlotType}
                            disabled
                            inputProps={{
                                name: 'slotType',
                                id: 'slotType',
                            }}
                        >
                            <MenuItem value="2">Advertiser Slot</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="row" style={{marginBottom:20}}>
                <div className="col-12">
                    <FormControl>
                        <InputLabel variant="standard">Number of Slots</InputLabel>
                        <Select
                            variant="standard"
                            value={quantitaSlots}
                            onChange={handleChangeQuantitaSlot}
                            inputProps={{
                                name: 'quantitaSlots',
                                id: 'quantitaSlots',
                            }}
                            style={{width: 150}}
                        >
                            <MenuItem value="0">0 (Delete Slots)</MenuItem>
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="2">2</MenuItem>
                            <MenuItem value="3">3</MenuItem>
                            <MenuItem value="4">4</MenuItem>
                            <MenuItem value="5">5</MenuItem>
                            <MenuItem value="6">6</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>

        </div>
    );
}
export default Availability;
import React, { useState, useEffect, useContext } from 'react'
import "./promoCodeDetail.css";
import config from '../../config';
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../contexts/authContext';
import { Country } from '../../App';
import axios from 'axios';
import CommonUtilities from "../../components/CommonUtilities";
import CircularProgress from '@mui/material/CircularProgress';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { useParams } from "react-router-dom";
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import FileSaver from 'file-saver';
import labelData from '../../labels.json'

const theme = createTheme({
palette: {
  secondary: {
    main: red[500],
  },
},
});


const PromoCodeDetail = () => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [country, setCountry] = useContext(Country); 
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [promoCode, setPromoCode] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [ccCampaignId, setCcCampaignId] = useState("");
    const [bcCampaignId, setBcCampaignId] = useState("");
    const [updating, setUpdating] = useState(false);
    const [openApproveCampaign, setOpenApproveCampaign] = useState(false);
    const [openRejectCampaign, setOpenRejectCampaign] = useState(false);
    const [rejectReason, setRejectReason] = useState("");
    const [tabValue, setTabValue] = useState(0);
    const [logRow, setLogRow] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [editPromoCodeDialog, setEditPromoCodeDialog] = useState(false);

    //stati dialog edit promo code
    const [diaDateRange, setDiaDateRange] = useState([undefined, undefined]);
    const [diaPromoCode, setDiaPromoCode] = useState('');
    const [diaDiscount, setDiaDiscount] = useState('');
    const [diaComment, setDiaComment] = useState('');
    const [diaDateError, setDiaDateError] = useState('');
    const [diaQuantityUsage, setDiaQuantityUsage] = useState(1);
    const [diaDiscountType, setDiaDiscountType] = useState(1);
    const [errorMsg, setErrorMsg] = useState('');

    const labels = labelData.labels;
    const { kPromoCode } = useParams();

    useEffect(() =>  {   
      loadPromoCode();
    }, []);

    useEffect(() => {
        if(editPromoCodeDialog && diaDateRange[0] && diaDateRange[1]){
            const startDate = new Date(diaDateRange[0].getFullYear(), diaDateRange[0].getMonth(), diaDateRange[0].getDate());
            const endDate = new Date(diaDateRange[1].getFullYear(), diaDateRange[1].getMonth(), diaDateRange[1].getDate());

            if(endDate < startDate){
                setDiaDateError("End date can't be lower than start date");
            }else{
                setDiaDateError('');
            }
        }
    }, [diaDateRange])

    const loadPromoCode = () => {

        setLoading(true);

        let url = `${config.API_URL}/promo-codes`;

        let params = {
            kPromoCode,
        };

        axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            }
        ).then((response) => {

            setPromoCode(response.data[0]);
            setLoading(false);
        }, (error) => {

            if(error.response.status === 401){
                auth.signOut();
            }else{
                console.error(error.response);
            }

        });

        
        //get campaigns of promo code
        getCampaigns(kPromoCode);
        
        
        //get logs
        url = `${config.API_URL}/log-trace`;
        
        axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            }
        ).then((response) => {
            //console.log('response.data', response.data)
            setLogRow(response.data);
            
        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }
        });
    }

    const getCampaigns = (kPromoCode, dontStopLoading = false) => {
        let url = `${config.API_URL}/admin-campaigns`;

        let params = {
            kPromoCode,
        };

        axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            }
        ).then((response) => {
            setCampaigns(response.data);
            if(!dontStopLoading)setLoading(false)
        }, (error) => {

            if(error.response.status === 401){
                auth.signOut();
            }else{
                console.error(error.response);
            }
        });
    }

    const modPromoCode = async() => {
        setUpdating(true);

        setErrorMsg('');
        setDiaDateError('');

        const url = `${config.API_URL}/promo-code-mod`;

        const params = {
            kPromoCode,
            beginDate: CommonUtilities.getStringFromDate(diaDateRange[0]),
            endDate: CommonUtilities.getStringFromDate(diaDateRange[1]),
            promoCode: diaPromoCode,
            discount: diaDiscount,
            dComment: diaComment,
            pQuantityByUser: diaQuantityUsage,
            pDiscountType: diaDiscountType
        };

        axios.post(
            url,
            params,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                }  
            }
        ).then((response) => {
            if(response.status===200){
                handleCloseAddPromoDialog();
                loadPromoCode();
            }
            setUpdating(false);
        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                if(error.response.data === -2){
                    setErrorMsg(labels['error-promo-code-ins-2']);
                }else if(error.response.data === -3){
                    setErrorMsg(labels['error-promo-code-ins-3']);
                }else if(error.response.data === -4){
                    setErrorMsg(labels['error-promo-code-ins-4']);  
                }else if(error.response.data === -6){
                    setErrorMsg(labels['error-promo-code-mod-1']);  
                }else{
                    setErrorMsg(labels['error-gen-0']);
                }

                setUpdating(false);
            }
        });
    }

    const campaignsToExcel = async() => {
        setLoading(true); 
        try{
            getCampaigns(kPromoCode, true);
            let url = `${config.API_URL}/excel-promo-code-campaign`;

            let params = {
                kPromoCode,
                promoCodeDesc: promoCode.PROMO_CODE,
                promoCodeValue: `${CommonUtilities.formatPositiveIntNum(promoCode.DISCOUNT)} ${promoCode.DISCOUNT_TYPE === 2 ? '€' : '%'}`
            };

            let response = await axios.get(
                url,
                {   
                    responseType: 'arraybuffer',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },
                    params  
                }
            )
            if(response.status===200){
                const blob = new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                FileSaver.saveAs(blob, [ promoCode.PROMO_CODE + "-" + moment().format("YYYYMMDDHHmmss") + ".xlsx" ]);
                setLoading(false);
            }
        }catch(error){
            if(error.response.status === 401){
                auth.signOut();
            }else{
                console.error(error.response);
            }
        }
        
    }

    const handleChangeTab = (event, value) => {
        setTabValue(value);
    }

    const goToUserDetail = (event, kUser) => {
        event.preventDefault();
        history.push(`/user/${kUser}`)
    }

    const gotoCampaignDetail = (event, kCampaign) =>  {
        event.preventDefault();
        history.push(`/campaign/${kCampaign}`);
    }

    const handleOpenEditPromoDialog = () => {
        //converto le date del db in date per il componente
        let datesToFormat = {
            startYear: promoCode.BEGIN_DATE.substring(0,4),
            endYear: promoCode.END_DATE.substring(0,4),
            startMonth: promoCode.BEGIN_DATE.substring(4,6),
            endMonth: promoCode.END_DATE.substring(4,6),
            startDay: promoCode.BEGIN_DATE.substring(6,8),
            endDay: promoCode.END_DATE.substring(6,8),
        }
        getCampaigns(kPromoCode);
        setDiaDateRange([new Date(datesToFormat.startYear, datesToFormat.startMonth-1, datesToFormat.startDay), new Date(datesToFormat.endYear, datesToFormat.endMonth-1, datesToFormat.endDay)])
        setDiaPromoCode(promoCode.PROMO_CODE);
        setDiaDiscount(CommonUtilities.formatPositiveIntNum(promoCode.DISCOUNT));
        setDiaComment(promoCode.DCOMMENT);
        setDiaDiscountType(promoCode.DISCOUNT_TYPE);
        setDiaQuantityUsage(promoCode.QUANTITY_BY_USER);
        setErrorMsg('');
        setEditPromoCodeDialog(true);
    }

    const handleCloseAddPromoDialog = () => {
        setDiaDateRange([undefined, undefined]);
        setDiaPromoCode("");
        setDiaDiscount("");
        setDiaComment("");
        setErrorMsg('');
        setDiaQuantityUsage(1);
        setDiaDiscountType(1);
        setEditPromoCodeDialog(false);
    }

    const checkValue = (value) => {
        if(diaDiscountType === 1){
            if(value > 100){
                return;
            }else{
                setDiaDiscount(value);
            }
        }else{
            setDiaDiscount(value);
        }
    }

    const gotoMainList = () => {
        history.push(`/promo-codes`)
    }

    return (
        <div className="promoCode-detail">
            <ThemeProvider theme={theme}>
                {loading &&  (<div className="circular-progress"><CircularProgress /></div>)}

                {updating && (
                    <Box sx={{ width: '100%' }} style={{position: "fixed", top: 0, zIndex: 10000}}>
                        <LinearProgress />
                    </Box>
                )}

 
                {promoCode !== undefined && (
                    <Card style={{width: '100%'}}>
                        <div className='custom-detail-header'>
                            <IconButton className='back-toList-btn' onClick={gotoMainList}>
                                <ArrowBackIcon/>
                            </IconButton>
                            <CardHeader
                                title={`Promo Code: ${kPromoCode}`}
                                className='custom-card-head'
                                action={<Button
                                    sx={{marginLeft:'212px'}}
                                    variant="contained"
                                    disabled={promoCode.STATUS_COD===3}
                                    onClick={handleOpenEditPromoDialog}>
                                        MODIFY PROMO CODE
                                    </Button>}
                            />
                        </div>
                        <Tabs value={tabValue} sx={{marginLeft: '16px'}} onChange={handleChangeTab} aria-label="basic tabs example">
                            <Tab label="Detail" id='simple-tab-1'/>
                            <Tab label="Log" id='simple-tab-2'/>
                        </Tabs>
                        {tabValue===0 ? (
                        <CardContent>
                            <Paper style={{maxWidth: 600}}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="left">Id:</TableCell>
                                                <TableCell align="left"><strong>{promoCode.KPROMO_CODE}</strong></TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Status:</TableCell>
                                                <TableCell align="left">{promoCode.STATUS_DESC}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Country:</TableCell>
                                                <TableCell align="left">{promoCode.DCOUNTRY}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Begin Date:</TableCell>
                                                <TableCell align="left">{moment(promoCode.BEGIN_DATE).format('MMMM Do YYYY')}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">End Date:</TableCell>
                                                <TableCell align="left">{moment(promoCode.END_DATE).format('MMMM Do YYYY')}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Promo Code:</TableCell>
                                                <TableCell align="left">{promoCode.PROMO_CODE}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Discount:</TableCell>
                                                <TableCell align="left">{CommonUtilities.formatPositiveIntNum(promoCode.DISCOUNT)}{promoCode.DISCOUNT_TYPE === 2 ? '€' : '%'}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Reuse Quantity:</TableCell>
                                                <TableCell align="left">{promoCode.QUANTITY_BY_USER === 99 ? 'Unlimited' : promoCode.QUANTITY_BY_USER}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Comment:</TableCell>
                                                <TableCell align="left">{promoCode.DCOMMENT}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell align="left">Creation Date:</TableCell>
                                                <TableCell align="left">{CommonUtilities.getFormatDateTime(promoCode.DATA_INSERIMENTO, 'MMMM Do YYYY HH:mm:ss')}</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>                
                            </Paper>
                            <CardHeader
                                sx={{paddingLeft:'0'}}
                                title={`List of Campaigns`}
                                action={<Button
                                            sx={{marginLeft:'261px'}}
                                            variant="contained"
                                            onClick={campaignsToExcel}>
                                                Extract Campaigns
                                        </Button>}
                            />
                            <Paper style={{maxWidth: 600}}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Camp. Id</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Client</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                            campaigns.map((campaign) => (
                                                <TableRow
                                                    key={campaign.KCAMPAIGN}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="left"><a href="#" onClick={(event) => gotoCampaignDetail(event, campaign.KCAMPAIGN)}>{campaign.KCAMPAIGN}</a></TableCell>
                                                    <TableCell align="left"><a href="#" onClick={(event) => gotoCampaignDetail(event, campaign.KCAMPAIGN)}>{campaign.DCAMPAIGN}</a></TableCell>
                                                    <TableCell align="left"><a href="#" onClick={(event) => goToUserDetail(event, campaign.KUSER_CREATION)}>{campaign.DUSER_CREATION}</a></TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>   
                            </Paper>
                        </CardContent>)
                        :
                        (<CardContent>
                            <Paper style={{maxWidth: 800}}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell>User</TableCell>
                                                <TableCell>Camp. Id</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                            logRow.map((log) => (
                                                <TableRow
                                                    key={log.KLOG}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="left">{CommonUtilities.formatDateTime(log.DATA_LOG)}</TableCell>
                                                    <TableCell align="left">{log.INVOKER_KUSER===-19 ? log.DUSER : log.INVOKER_DUSER}</TableCell>
                                                    <TableCell align="left">{log.KCAMPAIGN}</TableCell>
                                                    <TableCell align="left">{log.KACTION === 61 && log.NOTES !== null ? log.NOTES.split('|').map(item => {return <div>{item}</div>}) : log.DACTION}</TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </CardContent>)}
                    </Card>    
                )}
                <Dialog
                    open={editPromoCodeDialog}
                    onClose={() => false}
                >
                    <DialogTitle>
                        Modify Promo Code
                    </DialogTitle>
                    <DialogContent>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div style={{marginTop:'10px'}}>
                                <DatePicker
                                    label="Start Date"
                                    value={diaDateRange[0]}
                                    inputFormat="dd/MM/yyyy"
                                    disabled={campaigns.length > 0}
                                    onChange={(newValue) => {
                                        setErrorMsg('');
                                        setDiaDateRange([newValue, diaDateRange[1]]);
                                    }}
                                    renderInput={(params) => <TextField className='date-field' {...params} />}
                                />
                                &nbsp;
                                <DatePicker
                                    label="End Date"
                                    value={diaDateRange[1]}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newValue) => {
                                        setErrorMsg('');
                                        setDiaDateRange([diaDateRange[0], newValue]);
                                    }}
                                    renderInput={(params) => <TextField className='date-field' {...params} />}
                                />
                            </div>
                        </LocalizationProvider>

                        <div style={{display:'flex',alignItems:'bottom', marginTop:5}}>
                            <TextField sx={{width:'100%'}} label="Promo Code" variant="standard" value={diaPromoCode} disabled={campaigns.length > 0} onChange={(event) => {setErrorMsg(''); setDiaPromoCode(event.target.value);}}></TextField>
                            <Select
                                value={diaQuantityUsage}
                                disabled={campaigns.length > 0}
                                className="select-to-adj-svg"
                                onChange={(event) => {setErrorMsg(''); setDiaQuantityUsage(event.target.value);}}
                                variant="standard" 
                                size="small"
                                style={{width: 120, marginLeft: 15, alignItems: 'flex-end'}}
                                label="Quantity by User"
                                color="primary"
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={99}>Unlimited</MenuItem>
                            </Select>
                        </div>
                        <div style={{display:'flex',alignItems:'bottom', marginTop:5}}>
                            <TextField sx={{width:'30%'}} type='number' label="Discount (%)" variant="standard" value={diaDiscount} disabled={campaigns.length > 0} onChange={(event) => checkValue(event.target.value)}></TextField>
                            <Select
                                value={diaDiscountType}
                                disabled={campaigns.length > 0}
                                className="select-to-adj-svg"
                                onChange={(event) => {setErrorMsg(''); setDiaDiscount(''); setDiaDiscountType(event.target.value);}}
                                variant="standard" 
                                size="small"
                                label=""
                                style={{marginLeft: 10, alignItems: 'flex-end'}}
                                color="primary"
                                >
                                    <MenuItem value={1}>%</MenuItem>
                                    <MenuItem value={2}>€</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <TextField sx={{width:'100%'}} label="Comment" variant="standard" value={diaComment} onChange={(event) => setDiaComment(event.target.value)}></TextField>
                        </div>

                        {diaDateError !== '' 
                        ? 
                            <h5 className="msg-error">{diaDateError}</h5> 
                        :
                            errorMsg !== '' 
                            ?
                            <h5 className="msg-error">{errorMsg}</h5> 
                            :
                            <h5 className="msg-error">&nbsp;</h5>
                        }
                    </DialogContent>
                    <DialogActions sx={{justifyContent:'center'}}>
                        <Button
                            variant="outline"
                            onClick={handleCloseAddPromoDialog}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!diaDateRange[0] || !diaDateRange[1] || diaPromoCode.trim() === '' || diaPromoCode.trim().length < 3  || !diaDiscount || parseInt(diaDiscount) <= 0 || diaDateError!==''}
                            variant="contained"
                            onClick={modPromoCode}
                        >
                            Confirm Promo Code
                        </Button>
                    </DialogActions>
                </Dialog>                  
            </ThemeProvider>
        </div>
    )
}

export default PromoCodeDetail
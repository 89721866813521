import React, { useContext, useState, useEffect } from 'react';

import "./header.css";
import config from '../../config';
import Card from '@mui/material/Card';
import { AuthContext } from '../../contexts/authContext'
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PasswordIcon from '@mui/icons-material/Password';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Logout from '@mui/icons-material/Logout';
import { useLocation }  from 'react-router-dom';
import axios from 'axios';
import { Country } from '../../App';

const arrCheckRedirect = [
                          {child: '/user/', parent: '/users'}, 
                          {child: '/campaign/', parent: '/campaigns'},
                          {child: '/promo-code/', parent: '/promo-codes'}
                        ];

const Header = (props) => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [country, setCountry] = useContext(Country);
  const [countries, setCountries] = useState([]);
  const [kCountry, setKCountry] = useState(0);  
  const [countryIndex, setCountryIndex] = useState(0);

  const { pathname }  = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorElPop, setAnchorElPop] = useState(null);
  const openPop = Boolean(anchorElPop);

  useEffect(() =>  {  
    loadCountries();
  }, []);

  const loadCountries = async() => {
    const url = `${config.API_URL}/admin-countries`;
    const params = {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
      },
      params: {}
    };

    const response = await axios.get(url, params);

    if(response.data.length > 0){
      let arrCountries = response.data; 

      setCountries(arrCountries);

      const myKCountry = parseInt(country.split('|')[0]);

      setCountryIndex(arrCountries.findIndex(item => item.KCOUNTRY === myKCountry));
      setKCountry(myKCountry);
    }
  }

  const handleMenuCountryClick = (event, index) => {
    
    handleClosePop();

    if(index !== countryIndex){
      resetLocalStorageParams();

      setCountryIndex(index);
  
      const kCountry = parseInt(countries[index].KCOUNTRY);
      localStorage.setItem('preferredCountry', kCountry);
      setKCountry(kCountry);

      const myKCountry = `${countries[index].KCOUNTRY}|${countries[index].DETAIL_LEVEL_DEFAULT}|${countries[index].FLAG_REJECT_MSG}`;

      setCountry(myKCountry);
      
      let redirectDone = false;

      arrCheckRedirect.forEach(item => {
        if(window.location.href.indexOf(item.child) > 0){
          history.push(item.parent);
          redirectDone = true;
        }
      });

      if(!redirectDone){
        window.location.reload();
      }

    }
  };

  const handleClickPop = (event) => {
    if(countries.length > 1){
      setAnchorElPop(event.currentTarget);
    }
  };

  const handleClosePop = () => {
    setAnchorElPop(null);
  };
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSignOut = () => {
    auth.signOut()
    history.push('/')
  }

  function handleChangePassword() {
    history.push('changepassword')
  }

  const resetLocalStorageParams = () => {
    localStorage.removeItem('page');
    localStorage.removeItem('firstRecord');
    localStorage.removeItem('filter-type');
    localStorage.removeItem('filter-val');
    localStorage.removeItem('userListTab');
    localStorage.removeItem('sort-header');
    localStorage.removeItem('sort-direction');
  }

  const handleMenuHome = () => {
    resetLocalStorageParams();
    history.push('/home');
  }

  const handleMenuCampaigns = () => {
    resetLocalStorageParams();
    history.push('/campaigns');
  }

  const handleMenuPromoCodes = () => {
    resetLocalStorageParams();
    history.push('/promo-codes');
  }

  const handleMenuUsers = () => {
    resetLocalStorageParams();
    history.push('/users');
  }

  return (
      <div className="header">
        <div className="content">
            <div className="logo">
              <img src="assets/images/systrategy_logo.svg" alt="" onClick={handleMenuHome}/>
              {/*<img src="assets/images/santa.png" className="santa" alt=""/>*/}
            </div>

            <div className="menu-link">
              <Stack direction="row" spacing={3}>
                <Button
                  variant={pathname === "/home" ? "outlined" : "text"}
                  className="btn"
                  key={1}
                  onClick={handleMenuHome}>
                    HOME
                </Button>     
                <Button
                  variant={pathname.includes('/campaign') ? 'outlined' : 'text'}
                  className="btn"
                  key={2}
                  //disabled
                  onClick={handleMenuCampaigns}>
                    CAMPAIGNS
                </Button>
                <Button
                  variant={pathname.includes('/promo-code') ? 'outlined' : 'text'}
                  className="btn"
                  key={3}
                  //disabled
                  onClick={handleMenuPromoCodes}>
                    CODE PROMO
                </Button>                           
                <Button
                  variant={pathname.includes('/user') ? 'outlined' : 'text'}
                  className="btn"
                  key={4}
                  //disabled
                  onClick={handleMenuUsers}>
                    USERS
                </Button>
              </Stack>        
            </div>

            <div className="profile-link">

              <div className="country-box">
                <Typography color="text.secondary" component="div" className="country-label">
                  {countries.length > 0 ? 
                   <Chip 
                      className={countries.length > 1 ? "chip-country" : "chip-country disabled"}
                      onClick={handleClickPop}
                      label={<span>{countries[countryIndex].DCOUNTRY} {countries.length > 1 ? <ArrowDropDownIcon style={{verticalAlign: "middle", marginLeft: 5}}/> : "" } </span>}
                      variant="outlined"
                      avatar={<Avatar src={`/assets/images/flag-${countries[countryIndex].KCOUNTRY}.svg`} />}
                    />
                    : ""}
                </Typography> 

                <Popover
                  open={openPop}
                  anchorEl={anchorElPop}
                  onClose={handleClosePop}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >

                  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem className="country-option" alignItems="flex-start" sx={{cursor: "pointer"}} onClick={(event) => handleMenuCountryClick(event, 0)}>
                      <ListItemAvatar sx={{marginTop: 0, minWidth: "40px"}}>
                        <Avatar alt="France" src="/assets/images/flag-1.svg" sx={{ width: 24, height: 24}} />
                      </ListItemAvatar>
                      <ListItemText
                        primary="France"
                      />
                    </ListItem>

                    <ListItem className="country-option" alignItems="flex-start" sx={{cursor: "pointer"}} onClick={(event) => handleMenuCountryClick(event, 1)}>
                      <ListItemAvatar sx={{marginTop: 0, minWidth: "40px"}}>
                        <Avatar alt="Belgium" src="/assets/images/flag-2.svg" sx={{ width: 24, height: 24}} />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Belgium"
                      />
                    </ListItem>

                    <ListItem className="country-option" alignItems="flex-start" sx={{cursor: "pointer"}} onClick={(event) => handleMenuCountryClick(event, 2)}>
                      <ListItemAvatar sx={{marginTop: 0, minWidth: "40px"}}>
                        <Avatar alt="Italy" src="/assets/images/flag-3.svg" sx={{ width: 24, height: 24}} />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Italy"
                      />
                    </ListItem>
                  </List>
                </Popover>
              </div>

              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                  <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                  <Avatar />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem className='user-menu-name'>
                  {localStorage.getItem('nome')} {localStorage.getItem('cognome')}
                  <br />
                  <span className="email">{localStorage.getItem('user_email')}</span>
                </MenuItem>
                <MenuItem onClick={handleChangePassword}>
                  <ListItemIcon>
                    <PasswordIcon fontSize="small" />
                  </ListItemIcon>
                  Change Password
                </MenuItem>
                <MenuItem onClick={handleClickSignOut}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
        </div>
      </div>
  )
}

export default Header;

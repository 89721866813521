import React, { useState, useEffect, useContext } from 'react';
import './accountDetail.css';
import config from '../../config';
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../contexts/authContext';
import axios from 'axios';
import CommonUtilities from "../../components/CommonUtilities";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import labelData from '../../labels.json';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { useParams } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Draggable from 'react-draggable';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

let jsonCodes = {
    industryType: [...require('./json/industrytypeEN.json')].sort((a, b) => a.Code < b.Code ? -1 : (a.Code > b.Code ? 1 : 0 )),
    legalStatus: [...require('./json/legalstatusbeEN.json')].sort((a, b) => a.Code < b.Code ? -1 : (a.Code > b.Code ? 1 : 0 )),
};

const BOUNCE_TIMEOUT = 1500;

//questo non è il top: nel senso che la lista delle descrizioni delle country dovrebbe arrivare già dalla s.p.
//si può fare solo perchè, per ora, gli utenti sono collegati ad una sola country. Se fosse una lista di country non funzionerebbe
const countries = ['', 'France', 'Belgium'];

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><defs><style>.cls-1{fill:none;}</style></defs><title>locked</title><path d="M24,14H22V8A6,6,0,0,0,10,8v6H8a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2V16A2,2,0,0,0,24,14ZM12,8a4,4,0,0,1,8,0v6H12ZM24,28H8V16H24Z" style="fill: rgb(255,255,255);"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 0.5,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#1976d2',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#1976d2',
      marginTop: 4,
      width: 22.5,
      height: 22.5,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 2.1,
        top: 3,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><defs><style>.cls-1{fill:none;}</style></defs><title>unlocked</title><path d="M24,14H12V8a4,4,0,0,1,8,0h2A6,6,0,0,0,10,8v6H8a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2V16A2,2,0,0,0,24,14Zm0,14H8V16H24Z" style="fill: rgb(0,0,0);"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" /></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 0.5,
      backgroundColor: theme.palette.mode === 'dark' ? '#1976d2' : '#1976d2',
      borderRadius: 20 / 2,
      width: 35,
      height: 14,
      marginTop: 1.5,
      marginLeft: 0.5
    },
  }));  

function PaperComponent(props) {
    return (
      <Draggable
        handle="#disable-mfa-dialog"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
}

const regexPasswordAdmin = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{16,}$/;
const regexPasswordUser  = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{12,}$/;

const AccountDetail = (props) => {
    const auth = useContext(AuthContext);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [row, setRow] = useState([]);
    const [flagEnabled, setFlagEnabled] = useState(false);    
    const [axCode, setAxCode] = useState("");
    const [handlerDebounce, setHandlerDebounce] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [logRow, setLogRow] = useState([]);
    const [superAdmin, setSuperAdmin] = useState(localStorage.getItem('super_admin') !== null ? localStorage.getItem('super_admin') : 'N');
    const { kUser } = useParams();
    const [userIsAdmin, setUserIsAdmin] = useState(useParams().kUser.split('&')[1] !== 'adm' ? false : true);
    const [locked, setLocked] = useState(undefined);
    const [mfaStatus, setMfaStatus] = useState(false);
    const [openMfaDialog, setOpenMfaDialog] = useState(false);
    const [forcePasswordChange, setForcePasswordChange] = useState('');
    const [openForcePasswordDialog, setOpenForcePasswordDialog] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const labels = labelData.labels;
    const [snackMsg, setSnackMsg] = useState("");
    const [snackSeverity, setSnackSeverity] = useState("");
    const [snackOpen, setSnackOpen] = useState(false)

    useEffect(() => {
        setLoading(true);
        let url = `${config.API_URL}/users`;

        let params = {
            kUser: kUser.split('&')[0],
            flagAutoRegUsers: superAdmin === 'N' ? 'Y' : kUser.split('&')[1] !== 'adm' ? 'Y' : 'N' 
        }

        axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            }
        ).then((response) => {
            if(response.data.length === 1){
                setRow(response.data[0]);
                setAxCode(response.data[0].AX_CODE);
                setLocked(response.data[0].USR_LOCKED);
                setFlagEnabled(response.data[0].FLAG_ATTIVO === 'Y' ? true : false);
            }

        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }
        });

        getLogTrace();
    }, []);

    useEffect(() => {
        if(Object.keys(row).length > 2) {
            getUserMfaStatus();
        }
    },[row])

    const getLogTrace = async() => {
        let url = `${config.API_URL}/log-trace`;
        let params = {
            kUser: kUser.split('&')[0],
            flagAutoRegUsers: superAdmin === 'N' ? 'Y' : kUser.split('&')[1] !== 'adm' ? 'Y' : 'N' 
        }
        axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            }
        ).then((response) => {
            //console.log('response.data', response.data)
            setLogRow(response.data);
            setLoading(false);
        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }
        });
    }

    const modProfile = (myFlagEnabled, myAxCode) => {
        /*
        if(superAdmin === 'N'){
            return;
        }
        */
        setUpdating(true);

        const url = `${config.API_URL}/admin-profile-mod`;

        const params = {
            kUser: kUser.split('&')[0],
            flagEnabled: myFlagEnabled ? 'Y' : 'N',
            axCode: myAxCode,
        };

        axios.post(
            url,
            null,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            },

        ).then((response) => {
            setUpdating(false);

        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
                const errorCode = error.response.data;
            }
        });
    }

    const resetUserLock = async() => {
        setUpdating(true);
        try{
            const url = `${config.API_URL}/reset-attempt`;

            const params = {
                userCognitoSub: row.COGNITO_SUB
            }

            const response = await axios.post(
                url,
                null,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },
                    params  
                },
    
            )

            if(response.status===200){
                setLocked('N');
                setUpdating(false);
            }
        }catch(error){
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }
        }
    }

    const handleChangeStatus = (event) => {
        setFlagEnabled(event.target.checked);
        modProfile(event.target.checked, axCode);
    }

    const handleChangeAxCode = (event) => {
        setAxCode(event.target.value);
        clearTimeout(handlerDebounce);
        setHandlerDebounce(setTimeout(() => modProfile(flagEnabled, event.target.value), BOUNCE_TIMEOUT));
      };
    
    const handleChangeTab = (event, value) => {
        setTabValue(value);
    }  

    const handleSwitchLocked = () => {
        let newLockedVal = locked === 'N' ? 'Y' : 'N';
        setLocked(newLockedVal);
    }

    const handleOpenMfaDisableDialog = () => {
        setOpenMfaDialog(true);
    }

    const handleCloseMfaDisableDialog = () => {
        setOpenMfaDialog(false);
    }

    const handleOpenForcePasswordDialog = () => {
        setOpenForcePasswordDialog(true);
    }

    const handleCloseForcePasswordDialog = () => {
        setOpenForcePasswordDialog(false);
        setForcePasswordChange('');
        setShowPassword(false);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const getUserMfaStatus = async() => {
        try{
            let url = `${config.API_URL}/user-configuration`;
            let params = {
                userSub: row.COGNITO_SUB,
                flagAdmin: userIsAdmin ? 'Y' : 'N'
            }
            let response = await axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },
                    params
                }
            )
            if(response.status===200){
                setMfaStatus(response.data)
            }
        }catch(error){
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }
        }
    }

    const resetMFA = async() => {
        setLoading(true);
        setUpdating(true);
        try{
            const url = `${config.API_URL}/user-reset-mfa`;

            const params = {
                userSub: row.COGNITO_SUB,
                flagAdmin: userIsAdmin ? 'Y' : 'N',
                kUser: kUser.split('&')[0],
                userEmail: row.EMAIL_ADDRESS,
                invokerAdminEmail: localStorage.getItem('user_email')
            }

            const response = await axios.post(
                url,
                null,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`

                    },params
                },
    
            )

            if(response.status===200){
                getUserMfaStatus()
                if(!userIsAdmin){
                    getLogTrace();
                }
                setUpdating(false);
                setLoading(false);
                handleCloseMfaDisableDialog();

                setSnackMsg(labels["message-success"]);
                setSnackSeverity("success");
                setSnackOpen(true);

            }
        }catch(error){
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                setUpdating(false);
                setLoading(false);

                setSnackMsg(labels["error-gen"]);
                setSnackSeverity("error");
                setSnackOpen(true);
            }
        }
    }

    const forceUserPasswordChange = async() => {
        if(superAdmin!=='Y')return;
        setLoading(true);
        setUpdating(true);

        try{
            const url = `${config.API_URL}/force-change-password`;

            const params = {
                userSub: row.COGNITO_SUB,
                flagAdmin: userIsAdmin ? 'Y' : 'N',
                kUser: kUser.split('&')[0],
                userEmail: row.EMAIL_ADDRESS,
                //pass: forcePasswordChange
            }

            const response = await axios.post(
                url,
                null,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`

                    },params
                },
    
            )
            
            if(response.status===200){
                getUserMfaStatus()
                if(!userIsAdmin){
                    getLogTrace()
                }
                setUpdating(false);
                setLoading(false);
                handleCloseForcePasswordDialog();

                setSnackMsg(labels["message-success"]);
                setSnackSeverity("success");
                setSnackOpen(true);
            }
        }catch(error){
            //console.log(error)
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                setUpdating(false);
                setLoading(false);

                setSnackMsg(labels["error-gen"]);
                setSnackSeverity("error");
                setSnackOpen(true);
            }
        }
    }

    const checkForcePasswordConformity = () => {
        let testResult = undefined;
        if(userIsAdmin){
            testResult = regexPasswordAdmin.test(forcePasswordChange);
        }else{
            testResult = regexPasswordUser.test(forcePasswordChange);
        }
        return testResult;
    }

    const gotoMainList = () => {
        history.push(`/users`)
    }

    const legalStatus = parseInt(row.LISTA_KCOUNTRY) === 2 && jsonCodes.legalStatus.find(item => item.Cod === row.COMPANY_LEGAL_FORM) !== undefined 
                            ? 
                            `${jsonCodes.legalStatus.find(item => item.Cod === row.COMPANY_LEGAL_FORM).Code} - ${jsonCodes.legalStatus.find(item => item.Cod === row.COMPANY_LEGAL_FORM).Description}`
                            : 
                            "";

    const industryType = parseInt(row.LISTA_KCOUNTRY) === 2 && jsonCodes.industryType.find(item => item.Code === row.COMPANY_NAF) !== undefined
                            ?
                            `${row.COMPANY_NAF} - ${jsonCodes.industryType.find(item => item.Code === row.COMPANY_NAF).Description}`
                            :
                            "";

    const handleCloseSnack = () => {
        setSnackOpen(false);
        setSnackMsg('');
        setSnackSeverity('');
    };

    return (
        <div className="account-detail">
            
            {loading && (<div className="circular-progress"><CircularProgress /></div>)}

            {updating && (
                <Box sx={{ width: '100%' }} style={{position: "fixed", top: 0, zIndex: 10000}}>
                    <LinearProgress />
                </Box>
            )}

            <Snackbar open={snackOpen} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{vertical: 'top', horizontal:'center'}}>
                <Alert onClose={handleCloseSnack} severity={snackSeverity}>
                    {snackMsg}
                </Alert>
            </Snackbar>

            <Card style={{width: '100%'}}>
                <div className='custom-detail-header'>
                    <IconButton className='back-toList-btn' onClick={gotoMainList}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <CardHeader
                        title={"User: " + row.KUSER}
                    />
                </div>
                {!userIsAdmin&&(<Tabs value={tabValue} sx={{marginLeft: '16px'}} onChange={handleChangeTab} aria-label="basic tabs example">
                    <Tab label="Detail" id='simple-tab-1'/>
                    <Tab label="Log" id='simple-tab-2'/>
                </Tabs>)}
                {tabValue===0 ? (
                <CardContent>
                    <Paper className="grid-cnt thin">           
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Id</TableCell>
                                        <TableCell align="left">{row.KUSER}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Status</TableCell>
                                        {!row.COGNITO_SUB && (
                                            <TableCell align="left">Awaiting confirmation</TableCell>
                                        )}
                                        {row.COGNITO_SUB && (
                                        <TableCell align="left">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch checked={flagEnabled} onChange={handleChangeStatus} name="Enabled" />
                                                    }
                                                    label={flagEnabled ? "Enabled" : "Disabled"}
                                                />
                                            </FormGroup>
                                        </TableCell>
                                        )}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Locked</TableCell>
                                        <TableCell align="left">
                                            <FormControlLabel
                                                control={<MaterialUISwitch className={locked === 'N' ? 'locked-switch' : ''} sx={{ m: 1 }} checked={locked === 'N' ? false : true} disabled={locked === 'N' ? true : false} onClick={resetUserLock}/>}
                                                label= ""
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>MFA Status</TableCell>
                                        <TableCell 
                                            align="left"
                                        >
                                            {mfaStatus === true ? 'Active' : 'Inactive'} {mfaStatus === true ? <IconButton onClick={handleOpenMfaDisableDialog} color='primary'><CancelIcon/></IconButton> : ''}
                                        </TableCell>
                                    </TableRow>
                                    {
                                        superAdmin === 'Y' && (
                                            <TableRow>
                                                <TableCell>Force Password</TableCell>
                                                <TableCell align="left"><IconButton onClick={forceUserPasswordChange/*handleOpenForcePasswordDialog*/}><EditIcon/></IconButton></TableCell>
                                            </TableRow>
                                        )
                                    }
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="left">{row.NOME}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Surname</TableCell>
                                        <TableCell align="left">{row.COGNOME}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">Country:</TableCell>
                                        <TableCell align="left">{row.LISTA_DCOUNTRY}</TableCell>
                                    </TableRow>                                    
                                    <TableRow>
                                        <TableCell>Email</TableCell>
                                        <TableCell align="left">{row.EMAIL_ADDRESS}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Phone</TableCell>
                                        <TableCell align="left">{row.PHONE_NUMBER}</TableCell>
                                    </TableRow>
                                    {!userIsAdmin&&(<TableRow>
                                        <TableCell>AX Code</TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                variant="standard"
                                                id="axCode"
                                                value={axCode}
                                                onChange={handleChangeAxCode}
                                                autoComplete="off"
                                            />                                            
                                        </TableCell>
                                    </TableRow>)}
                                    <TableRow>
                                        <TableCell>Created on</TableCell>
                                        <TableCell align="left">{CommonUtilities.getFormatDateTime(row.DATA_INSERIMENTO, 'MMMM Do YYYY HH:mm:ss')}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Last modified on</TableCell>
                                        <TableCell align="left">{CommonUtilities.getFormatDateTime(row.DATA_ULTIMA_MODIFICA, 'MMMM Do YYYY HH:mm:ss')}</TableCell>
                                    </TableRow>                                   
                                    <TableRow>
                                        <TableCell>Country</TableCell>
                                        <TableCell align="left">{row.length!==0 ? row.LISTA_KCOUNTRY.split(',').map(paese => countries[paese]).join(', ') : ''}</TableCell>
                                    </TableRow>
                                    {!userIsAdmin&&(<>
                                        <TableRow>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell align="left">{row.COMPANY_NAME}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Address</TableCell>
                                            <TableCell align="left">{row.ADDRESS}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>ZipCode</TableCell>
                                            <TableCell align="left">{row.ZIPCODE}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>City</TableCell>
                                            <TableCell align="left">{row.CITY}</TableCell>
                                        </TableRow>
                                    </>)}

                                    {parseInt(row.LISTA_KCOUNTRY) === 1 && !userIsAdmin &&(
                                        <TableRow>
                                            <TableCell>Company Type</TableCell>
                                            <TableCell align="left">{row.COMPANY_TYPE}</TableCell>
                                        </TableRow>
                                    )}

                                    {parseInt(row.LISTA_KCOUNTRY) === 2 && !userIsAdmin &&(
                                        <>
                                            <TableRow>
                                                <TableCell>Company Legal</TableCell>
                                                <TableCell align="left">
                                                    {legalStatus}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Industry Type</TableCell>
                                                <TableCell align="left">
                                                    {industryType}
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )}
                                    
                                    {!userIsAdmin&&(<>
                                        <TableRow>
                                            <TableCell>Company Number</TableCell>
                                            <TableCell align="left">{row.COMPANY_SIRET}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>VAT Number</TableCell>
                                            <TableCell align="left">{row.COMPANY_TVA}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>User App Language</TableCell>
                                            <TableCell align="left">{row.PREFERRED_LANGUAGE_COD}</TableCell>
                                        </TableRow>
                                    </>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </CardContent>)
                :
                (<CardContent>
                    <Paper style={{maxWidth: 600}}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                    logRow.map((log) => (
                                        <TableRow
                                            key={log.KLOG}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left">{CommonUtilities.formatDateTime(log.DATA_LOG)}</TableCell>
                                            <TableCell align="left">{log.INVOKER_KUSER===-19 ? log.DUSER : log.INVOKER_DUSER}</TableCell>
                                            <TableCell align="left">{log.DACTION}</TableCell>
                                        </TableRow>
                                    ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </CardContent>)}
            </Card>
            <Dialog
                open={openMfaDialog}
                onClose={handleCloseMfaDisableDialog}
                PaperComponent={PaperComponent}
                aria-labelledby="disable-mfa-dialog"
                className='disable-mfa-dialog'
            >
                <DialogTitle style={{ cursor: 'move' }} id="disable-mfa-dialog">
                    Reset MFA
                </DialogTitle>
                <DialogContent>
                    Do you want to reset the MFA configuration for user {row.EMAIL_ADDRESS}?
                    <br></br>
                    <br></br>
                    This action can't be undone.
                </DialogContent>
                <DialogActions className='disable-mfa-dialog-actions'>
                    <Button variant="outlined" onClick={handleCloseMfaDisableDialog} autofocus>Cancel</Button>
                    <Button variant="contained"  onClick={resetMFA}>Reset MFA</Button>
                </DialogActions>    
            </Dialog>
            {/* <Dialog
                open={openForcePasswordDialog}
                onClose={handleCloseForcePasswordDialog}
                PaperComponent={PaperComponent}
                aria-labelledby="disable-mfa-dialog"
                className='disable-mfa-dialog'
            >
                <DialogTitle style={{ cursor: 'move' }} id="disable-mfa-dialog">
                    Force User Password
                </DialogTitle>
                <DialogContent className='force-psw-flex'>
                    Do you want to change the password for user {row.EMAIL_ADDRESS}?
                    
                    <OutlinedInput
                        className       ="center-text margin-psw-force"
                        name            ="forcePassword"
                        id              ="forcePassword"
                        placeholder     ="Password"
                        type            ={showPassword ? 'text' : 'password'}
                        autoComplete    ='new-password'
                        value           ={ forcePasswordChange }
                        onChange        ={ e => { setForcePasswordChange(e.target.value) } }
                        spellCheck      ={ false }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    
                    This action can't be undone.
                </DialogContent>
                <DialogActions className='disable-mfa-dialog-actions'>
                    <Button variant="outlined" onClick={handleCloseForcePasswordDialog} autofocus>Cancel</Button>
                    <Button variant="contained"  onClick={forceUserPasswordChange} disabled={!checkForcePasswordConformity()}>Force Password</Button>
                </DialogActions>    
            </Dialog> */}
            
        </div>
    )
}

export default AccountDetail;
import React, {useEffect, useContext, useState, createContext, useMemo} from 'react'
import './App.css'
import moment from 'moment';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from './contexts/authContext'
import SignIn from './routes/auth/signIn'
import ForgotPassword from './routes/auth/forgotPassword'
import ChangePassword from './routes/auth/changePassword'
import Home from './routes/home';
import Campaigns from './routes/campaigns';
import CampaignDetail from './routes/campaignDetail';
import Users from './routes/users';
import UserDetail from './routes/userdetail';
import PromoCodes from './routes/promoCodes';
import PromoCodeDetail from './routes/promoCodeDetail';
import configMFA from './routes/configMFA';
import axios from 'axios';
import config from './config.js';
import { AuthContext } from './contexts/authContext'
import CircularProgress from '@mui/material/CircularProgress';

export const Country = createContext({});

const SignInRoute: React.FunctionComponent = () => (
  <Router>
    <Switch>
      <Route path="/signin" component={SignIn} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/changepassword" component={ChangePassword} />
      <Route path="/" component={SignIn} />
    </Switch>
  </Router>
)

const MainRoute: React.FunctionComponent = () => {

  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [goToChangePwd, setGoToChangePwd] = useState(false);
  const [country, setCountry] = useState({});
  const isMFAToConfig = false;//!!localStorage.getItem('isMFAToConfig');

  useEffect(() => {
    //non serve se è da configurare l'mfa
    //if(isMFAToConfig){setLoading(false);return};

    async function init(){
      try{
        //await authContext.checkIfUserHasMFA();      
        const url = `${config.API_URL}/admin-profile`;
        let response = await axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authContext.sessionInfo.accessToken}`
                }   
            }
        )
        if(response.status === 200){
          const data = response.data;
          localStorage.setItem('user_email', data.EMAIL_ADDRESS);
          localStorage.setItem('super_admin', data.FLAG_SUPER_ADMIN);
          localStorage.setItem('nome', data.NOME);
          localStorage.setItem('cognome', data.COGNOME);

          //se ciò che arriva da db contiene la country salvata nel localStorage allora considero il localStorage altrimenti il valore db
          let preferredCountry:any = localStorage.getItem('preferredCountry')
          
          const preferredCountryPosition:number = data.LISTA_KCOUNTRY.split(',').indexOf(preferredCountry);

          if(preferredCountryPosition >= 0){
            preferredCountry = parseInt(preferredCountry);
          }else{
            preferredCountry = null;
          }
          // init country per utente (si da per certo che l'utente abbia almeno uno country assegnata)
          const initCountry:any = preferredCountry !== null 
                                    ?
                                      `${preferredCountry}|${data.LISTA_DETAIL_LEVEL_DEFAULT.split(',')[preferredCountryPosition]}|${data.LISTA_FLAG_REJECT_MSG.split(',')[preferredCountryPosition]}|`
                                    :
                                      `${data.LISTA_KCOUNTRY.split(',')[0]}|${data.LISTA_DETAIL_LEVEL_DEFAULT.split(',')[0]}|${data.LISTA_FLAG_REJECT_MSG.split(',')[0]}|`;

          //console.log(initCountry);

          setCountry(initCountry);

          //check password age.
          localStorage.setItem('force-change-pwd', 'false');
          const dataInserimento = moment(data.PWD_DATA_INSERIMENTO);
         
          if(dataInserimento.isBefore(moment().subtract(config.PWD_DAYS_LIMIT, "days"))){
            localStorage.setItem('force-change-pwd', 'true');
            setGoToChangePwd(true);
          }
        }
      }catch(error){
        console.log('error get datas')
      }
      
      setLoading(false);
    }
    init();
  }, []);

  if(loading){
    return <div className="circular-progress"><CircularProgress /></div>
  }else{
    if(goToChangePwd){
      return (
        <Router>
          <Switch>
              <Route path="/" component={ChangePassword} />
          </Switch>
        </Router>        
      );
    }else{
    return (
        <Country.Provider value={[country, setCountry]}>
          <Router>
                {/* {
                  isMFAToConfig ?
                  <Switch>
                    <Route path="/" component={configMFA}></Route>
                  </Switch>
                  : */}
                  <Switch>
                    <Route path="/changepassword" component={ChangePassword} />
                    <Route path="/home" component={Home} />
                    <Route path="/campaigns" component={Campaigns} />
                    <Route path="/campaign/:kCampaign" component={CampaignDetail} />
                    <Route path="/users" component={Users} />
                    <Route path="/user/:kUser" component={UserDetail} />
                    <Route path="/promo-codes" component={PromoCodes} />
                    <Route path="/promo-code/:kPromoCode" component={PromoCodeDetail} />
                    <Route path="/" component={Home} />
                  </Switch>
                {/* } */}
          </Router>
        </Country.Provider>
      )
    }
  }
}

const App: React.FunctionComponent = () => (

  <AuthProvider>
    <AuthIsSignedIn>
      <MainRoute />
    </AuthIsSignedIn>
    <AuthIsNotSignedIn>
      <SignInRoute />
    </AuthIsNotSignedIn>
  </AuthProvider>
)

export default App

import { ClientMetadata } from 'amazon-cognito-identity-js'
import React, { useState, useEffect, useContext } from 'react'
import config from '../config.js';
import axios from 'axios';
import * as cognito from '../libs/cognito'

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo: { username?: string; email?: string; sub?: string; accessToken?: string; refreshToken?: string }
  attrInfo?: any
  authStatus?: AuthStatus
  signInWithEmail?: any
  signUpWithEmail?: any
  signOut?: any
  verifyCode?: any
  getSession?: any
  sendCode?: any
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  setAttribute?: any
  refreshToken?: any
  /* requestTokenTOTP?: any
  verifySoftwareTOTP?: any
  enableMFA?: any
  signInWithTOTP?: any
  rememberDevice?: any
  getDevice?: any
  forceForgetDevice?: any
  checkIfUserHasMFA?: any
  listDevices?: any */
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading, 
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn: React.FunctionComponent = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn: React.FunctionComponent = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}


let refreshInterval:any = undefined;

const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [sessionInfo, setSessionInfo] = useState({})
  const [attrInfo, setAttrInfo] = useState([])

  useEffect(() => {

    async function getSessionInfo() {
      try {
        let recoverToken = localStorage.getItem('recoverToken') !== null ? '' + localStorage.getItem('recoverToken') : '';

        const isRevoverTokenValid = await cognito.isAccessTokenValid(recoverToken.toString());
        
        if(isRevoverTokenValid){
          
          let session: any = await getSession();

          await cognito.refreshToken(session);

          session = await getSession();

          setSessionInfo({
            accessToken: session.accessToken.jwtToken,
            refreshToken: session.refreshToken.token,
          });

          localStorage.setItem('recoverToken', session.accessToken.jwtToken);

          const attr: any = await getAttributes();
          setAttrInfo(attr);

          if(refreshInterval === undefined){
            refreshInterval = setInterval(refreshToken, config.REFRESH_TOKEN_INTERVAL);
          }

          setAuthStatus(AuthStatus.SignedIn);

        }else{
          setAuthStatus(AuthStatus.SignedOut);
        }

      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut);
        signOut();
      }
    }
    
    getSessionInfo();  

  }, [setAuthStatus, authStatus])


  if (authStatus === AuthStatus.Loading) {
    return null
  }

  async function refreshToken() {
    //qui si potrebbe implementare una chiamata backend per loggare il fatto che l'utente è ancora attivo
    try {
      let session: any = await getSession();

      await cognito.refreshToken(session);

      session = await getSession();

      setSessionInfo({
        accessToken: session.accessToken.jwtToken
      });

      localStorage.setItem('recoverToken', session.accessToken.jwtToken);
      
      setAuthStatus(AuthStatus.SignedIn)

    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
    }
  }

  async function signInWithEmail(username: string, password: string) {
    try {
      localStorage.removeItem("startDate2");
      localStorage.removeItem("endDate");
      localStorage.removeItem("kCountry");
      localStorage.removeItem("checkedGroups");

      let responseLogin:any = await cognito.signInWithEmail(username, password);

      /* if(responseLogin === 'SOFTWARE_TOKEN_MFA'){
        return responseLogin
      } */
      
      const session: any = await getSession();

      setSessionInfo({
        accessToken: session.accessToken.jwtToken
      });

      localStorage.setItem('recoverToken', session.accessToken.jwtToken);

      setAuthStatus(AuthStatus.SignedIn)

    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }

  async function signUpWithEmail(username: string, password: string, metaData:ClientMetadata) {
    try {
      //await cognito.signUpUserWithEmail(username, password, metaData)
    } catch (err) {
      throw err
    }
  }

  function signOut() {
    localStorage.removeItem("startDate2");
    localStorage.removeItem("endDate");
    localStorage.removeItem("kCountry");
    localStorage.removeItem("checkedGroups");
    localStorage.removeItem('user_email');
    localStorage.removeItem('super_admin');
    localStorage.removeItem('nome');
    localStorage.removeItem('cognome');
    localStorage.removeItem('isMFAToConfig');
    //localStorage.removeItem('preferredCountry');

    cognito.signOut()
    setAuthStatus(AuthStatus.SignedOut)
  }

  async function verifyCode(username: string, code: string) {
    try {
      await cognito.verifyCode(username, code)
    } catch (err) {
      throw err
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession()
      return session
    } catch (err) {
      throw err
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes()
      return attr
    } catch (err) {
      throw err
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr)
      return res
    } catch (err) {
      throw err
    }
  }

  async function sendCode(email: string) {
    try {
      await cognito.sendCode(email)
    } catch (err) {
      throw err
    }
  }

  async function forgotPassword(username: string, code: string, password: string) {
    try {
      await cognito.forgotPassword(username, code, password)
    } catch (err) {
      throw err
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword)
    } catch (err) {
      throw err
    }
  }

  /* async function requestTokenTOTP(){
    try {
      let response = await cognito.requestTokenTOTP()
      return response
    } catch (err) {
      throw err
    }
  }
  
  async function verifySoftwareTOTP(totpCode: string){
    try{
      let response = await cognito.verifySoftwareTOTP(totpCode)
      return response;
    }catch(err){
      throw err
    }
  }

  async function enableMFA() {
    try{
      let response = await cognito.enableMFA();
      let token:any = sessionInfo;

      const url = `${config.API_URL}/trace-mfa-activation`;

      await axios.post(
        url,
        null,
        {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token.accessToken}`
            }
        }
      )

      return response
    }catch(err){
      throw(err);
    }
  }

  async function signInWithTOTP(totpCode: string, username: string, password:string) {
    try{
      await cognito.signInWithTOTP(totpCode,username,password)
      const session: any = await getSession();

        setSessionInfo({
          accessToken: session.accessToken.jwtToken
        });
        
      window.localStorage.setItem('recoverToken', session.accessToken.jwtToken);

      setAuthStatus(AuthStatus.SignedIn)

      return session.accessToken.jwtToken

    }catch(err){
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }

  async function rememberDevice() {
    try{
      let response = await cognito.rememberDevice();
      return response;
    }catch(err){
      throw err;
    }
  }
  
  async function getDevice(){
    return await cognito.getDevice();
  }

  async function forceForgetDevice(){
    await cognito.forceForgotDevice();
  }

  async function checkIfUserHasMFA(){
    try{
      let response = await cognito.checkIfUserHasMFA();
      return response
    }catch{
      signOut();
    }
  }

  async function listDevices(){
    await cognito.listDevices();
  } */

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    refreshToken,
    /* requestTokenTOTP,
    verifySoftwareTOTP,
    enableMFA,
    signInWithTOTP,
    rememberDevice,
    getDevice,
    forceForgetDevice,
    checkIfUserHasMFA,
    listDevices */
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider

import React, { useContext, useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import GitHubIcon from '@mui/icons-material/GitHub';
import Link from '@mui/material/Link';

import logoImage from './logo.png'

import { AuthContext } from '../contexts/authContext';
import Header from './components/header';
import SideNav from './components/sideNav';
import Reporting from './admin/reporting';
import { integer } from 'aws-sdk/clients/frauddetector';

const startDateInit = new Date();
const endDateInit = new Date(new Date().setDate(new Date().getDate() + 7));

export default function Home() {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);

  const [listaKGroup, setListaKGroup] = useState([]);
  const [listaKFace, setListaKFace] = useState([]);
  const [startDate, setStartDate] = useState(startDateInit);
  const [endDate, setEndDate] = useState(endDateInit);
  const [country, setCountry] = useState(0);
  const [countryDetailLevel, setCountryDetailLevel] = useState(undefined);
  const [reload, setReload] = useState(false);

  const history = useHistory();

  const auth = useContext(AuthContext);

  function signOutClicked() {
    auth.signOut()
    history.push('/')
  }

  const handleSideNavExpand = (expanded:boolean) => {
    setSideNavExpanded(expanded)
  }

  const handleChangeDateRange = (startDate:any, endDate:any) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const handleChangeCheckedGroups = (checkedGroups:[]) => {
    setListaKGroup(checkedGroups);
  }

  const handleChangeCheckedFrames = (checkedFrames:[]) => {
    setListaKFace(checkedFrames);
  }

  const handleReload = (reload:boolean) => {
    setReload(reload);
  }

  return (
    <Grid container>

      <Header />

      <SideNav handleExpand={(expanded:boolean) => handleSideNavExpand(expanded)}  
               handleChangeDateRange={(starDate:any, endDate:any) => handleChangeDateRange(starDate, endDate)}  
               handleChangeCheckedGroups={(checkedGroups:[]) => handleChangeCheckedGroups(checkedGroups)}
               handleChangeCheckedFrames={(checkedFrames:[]) => handleChangeCheckedFrames(checkedFrames)}
               handleReload={(reload:boolean) => handleReload(reload)} 
      />

      <Reporting sideNavExpanded={sideNavExpanded} 
                listaKGroup={listaKGroup}
                listaKFace={listaKFace}
                startDate={startDate} 
                endDate={endDate}
                reload={reload} />

    </Grid>
  )
}

import React, { useState, useEffect, useContext } from 'react'
import "./campaignList.css";
import config from '../../config';
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../contexts/authContext';
import { Country } from '../../App';
import axios from 'axios';
import CommonUtilities from "../../components/CommonUtilities";
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const theme = createTheme({
    palette: {
        primary: {
            main: "#0c86c4",
        },    
    },
});

const CampaignList = (props) =>{

    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [country, setCountry] = useContext(Country);    
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [selectedHeader, setSelectedHeader] = useState(localStorage.getItem('sort-header')!==null ? localStorage.getItem('sort-header') : 'KCAMPAIGN');
    const [sortDirection, setSortDirection] = useState(localStorage.getItem('sort-direction')!==null ? localStorage.getItem('sort-direction') : 'desc');
    const [page, setPage] = useState(localStorage.getItem('page')!==null ? parseInt(localStorage.getItem('page')) : 1);
    const [firstRecord, setFirstRecord] = useState(localStorage.getItem('firstRecord')!==null ? parseInt(localStorage.getItem('firstRecord')) : 1);
    const [numRecords, setNumRecord] = useState(50);
    const [numPages, setNumPages] = useState(1);
    const [searchBy, setSearchBy] = useState(localStorage.getItem('filter-type')!==null ? parseInt(localStorage.getItem('filter-type')) : 1);
    const [searchTxt, setSearchTxt] = useState(localStorage.getItem('filter-val')!==null ? localStorage.getItem('filter-type')=== '1' ? localStorage.getItem('filter-val') : "" : "");
    const [handlerDebounce, setHandlerDebounce] = useState(null);
    const [searchStatusCod, setSearchStatusCod] = useState(localStorage.getItem('filter-val')!==null ? localStorage.getItem('filter-type')=== '2' ? parseInt(localStorage.getItem('filter-val')) : 0 : 0);

    useEffect(() => {
        localStorage.removeItem('page');
        localStorage.removeItem('firstRecord');
        localStorage.removeItem('filter-type');
        localStorage.removeItem('filter-val');
        localStorage.removeItem('userListTab');
        localStorage.removeItem('sort-header');
        localStorage.removeItem('sort-direction');
    }, [])

    useEffect(() => {
        loadGrid();
    }, [firstRecord, selectedHeader, sortDirection])

    useEffect(() => {
        if(!loading && searchBy === 1){
            clearTimeout(handlerDebounce);
            setHandlerDebounce(setTimeout(() => {
                loadGrid();
            }, 600));
        }
    }, [searchTxt]);

    useEffect(() => {
        if(!loading){
            loadGrid();
        }
    }, [searchBy, searchStatusCod]);

    const loadGrid = () => {
        setLoading(true);

        const url = `${config.API_URL}/admin-campaigns`;

        let dCampaign = "";
        let statusCod = "";
        if(searchBy === 1){
            dCampaign = searchTxt;
        }else if(searchBy === 2){
            statusCod = searchStatusCod !== 0 ? searchStatusCod : "";
        }
        
        const kCountry = country.split('|')[0];

        const params = {
            kCountry,
            dCampaign,
            statusCod,
            firstRecord,
            numRecords,
            orderByField: selectedHeader,
            orderByMode: sortDirection.toUpperCase()
        };

        axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            }
        ).then((response) => {
            if(response.data.length > 0){
                setRows(response.data);
                setNumPages(Number.isInteger(response.data[0].NUM_TOT/numRecords) ? response.data[0].NUM_TOT/numRecords : Math.floor(response.data[0].NUM_TOT/numRecords) + 1);
            }else{
                setRows([]);
                setNumPages(0);
            }
            setLoading(false);
        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }
        });
    }

    const sortTable = (columnId, sortDirection) => {
        let sortValue = ''
        //controllo per resettare a desc il sorting nel caso si cliccasse su un header differente dal precedente
        if(columnId !== selectedHeader){
            sortValue = 'desc';
        }else{
            sortValue = sortDirection==='desc' ? 'asc' : 'desc';
        }
        setSortDirection(sortValue);
        setSelectedHeader(columnId);
    }

    const gotoDetail = (event, kCampaign) =>  {
        event.preventDefault();
        localStorage.setItem('page', page);
        localStorage.setItem('firstRecord', firstRecord);
        localStorage.setItem('filter-type', searchBy);
        localStorage.setItem('filter-val', searchBy===1 ? searchTxt : searchStatusCod);
        localStorage.setItem('sort-header', selectedHeader);
        localStorage.setItem('sort-direction', sortDirection);
        history.push(`/campaign/${kCampaign}`);
    }

    const gotoUser = (event, kUser) =>  {
        event.preventDefault();
        history.push(`/user/${kUser}`);
    }

    const chipClass = (row) => {
        if(row.STATUS_DESC === 'CREATED'){
            return 'tag-created';
        }
        else if(row.STATUS_DESC === 'AWAITING APPROVAL'){
            return 'tag-waiting';
        }
        else if(row.STATUS_DESC === 'APPROVED'){
            return 'tag-approved';
        }
        else if(row.STATUS_DESC === 'REJECTED'){
            return 'tag-rejected';
        }
        else if(row.STATUS_DESC === 'DELETED'){
            return 'tag-deleted';
        }
    }

    const handleChangePage = (event, value) => {
        if(value === 1){
            setPage(1);
            setFirstRecord(1)
        }else{
            setPage(value);
            setFirstRecord(((value-1)*numRecords)+1);
        }
    }

    const handleChangeSearchBy = (event) => {
        setSearchBy(event.target.value);
    };

    const handleChangeSearchTxt = (event) => {
        setSearchTxt(event.target.value);
    };

    const handleChangeSearchStatusCod = (event) => {
        setSearchStatusCod(event.target.value);
    };
    
    return (
        <div className="campaign-list">
            <ThemeProvider theme={theme}>
                {loading && (<div className="circular-progress"><CircularProgress /></div>)}
                
                <Card style={{width: '100%'}}>
                    <CardHeader
                        title="Campaigns"
                    />
                    <CardContent>

                        <div className="filters">
                            <FormControl className="frm-ctrl">
                                <Select
                                    value={searchBy}
                                    onChange={handleChangeSearchBy}
                                    variant="standard" 
                                    size="small"
                                    style={{width: 100}}
                                    color="primary"
                                    >
                                        <MenuItem value={1}>Name</MenuItem>
                                        <MenuItem value={2}>Status</MenuItem>
                                </Select>
                            </FormControl>
                            
                            {searchBy === 1 && (
                                <FormControl>
                                    <TextField
                                        placeholder="Search Campaigns by attribute"
                                        value={searchTxt}
                                        onChange={handleChangeSearchTxt}
                                        variant="standard" 
                                        size="small"
                                        style={{width: 250}}
                                        color="primary"
                                    />
                                </FormControl>
                            )}

                            {searchBy === 2 && (
                                <FormControl>
                                    <Select
                                        value={searchStatusCod}
                                        onChange={handleChangeSearchStatusCod}
                                        variant="standard" 
                                        size="small"
                                        style={{width: 200}}
                                        color="primary"
                                        >
                                            <MenuItem value={0}>ALL STATUS</MenuItem>
                                            <MenuItem value={1}>CREATED</MenuItem>
                                            <MenuItem value={2}>AWAITING PAYMENT</MenuItem>
                                            <MenuItem value={3}>AWAITING APPROVAL</MenuItem>
                                            <MenuItem value={4}>APPROVED</MenuItem>
                                            <MenuItem value={99}>DELETED</MenuItem>
                                    </Select>
                                </FormControl>
                            )}                            
                        </div>

                        <Paper className="grid-cnt">    
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                id={'KCAMPAIGN'}
                                                align="center"
                                                sx={{width: '100px'}}
                                                >
                                                <TableSortLabel
                                                    id={'KCAMPAIGN'}
                                                    active={selectedHeader === 'KCAMPAIGN' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('KCAMPAIGN', sortDirection)}}
                                                    >Camp. Id
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                            id={'DCAMPAIGN'}
                                            align="left" 
                                            sx={{width: '230px'}}
                                            >
                                                <TableSortLabel
                                                    id={'DCAMPAIGN'}
                                                    active={selectedHeader === 'DCAMPAIGN' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('DCAMPAIGN', sortDirection)}}
                                                    >Name
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'DCOUNTRY'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'DCOUNTRY'}
                                                    active={selectedHeader === 'DCOUNTRY' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('DCOUNTRY', sortDirection)}}
                                                    >Country
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'LISTA_DLOCATION'}
                                                align="left"
                                                sx={{width: '160px'}}
                                                >
                                                <TableSortLabel
                                                    id={'LISTA_DLOCATION'}
                                                    active={selectedHeader === 'LISTA_DLOCATION' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('LISTA_DLOCATION', sortDirection)}}
                                                    >Location
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'DATA_INSERIMENTO'}
                                                align="left"
                                                sx={{width: '80px'}}
                                                >
                                                <TableSortLabel
                                                    id={'DATA_INSERIMENTO'}
                                                    active={selectedHeader === 'DATA_INSERIMENTO' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('DATA_INSERIMENTO', sortDirection)}}
                                                    >Ins. date
                                                </TableSortLabel>
                                            </TableCell>                                        
                                            <TableCell
                                                id={'BEGIN_DATE'}
                                                align="left"
                                                sx={{width: '90px'}}
                                                >
                                                <TableSortLabel
                                                    id={'BEGIN_DATE'}
                                                    active={selectedHeader === 'BEGIN_DATE' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('BEGIN_DATE', sortDirection)}}
                                                    >From date
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'END_DATE'}
                                                align="left"
                                                sx={{width: '90px'}}
                                                >
                                                <TableSortLabel
                                                    id={'END_DATE'}
                                                    active={selectedHeader === 'END_DATE' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('END_DATE', sortDirection)}}
                                                    >To date
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'STATUS_DESC'}
                                                align="center"
                                                sx={{width: '175px'}}
                                                >
                                                <TableSortLabel
                                                    id={'STATUS_DESC'}
                                                    active={selectedHeader === 'STATUS_DESC' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('STATUS_DESC', sortDirection)}}
                                                    >Status
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'GLOOH_RENDERED'}
                                                align="center"
                                                >
                                                <TableSortLabel
                                                    id={'GLOOH_RENDERED'}
                                                    active={selectedHeader === 'GLOOH_RENDERED' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('GLOOH_RENDERED', sortDirection)}}
                                                    >Creativity
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'DUSER_CREATION'}
                                                align="left"
                                                sx={{width: '140px'}}
                                                >
                                                <TableSortLabel
                                                    id={'DUSER_CREATION'}
                                                    active={selectedHeader === 'DUSER_CREATION' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('DUSER_CREATION', sortDirection)}}
                                                    >User
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'TOTAL_COST'}
                                                align="right"
                                                >
                                                <TableSortLabel
                                                    id={'TOTAL_COST'}
                                                    active={selectedHeader === 'TOTAL_COST' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('TOTAL_COST', sortDirection)}}
                                                    >Cost
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell align="left">Stripe Intent Id</TableCell>
                                            <TableCell align="left">C.C. Camp. Id</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="table-body">
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.KCAMPAIGN}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center"><a href="#" onClick={(event) => gotoDetail(event, row.KCAMPAIGN)}>{row.KCAMPAIGN}</a></TableCell>
                                            <TableCell align="left"><a href="#" onClick={(event) => gotoDetail(event, row.KCAMPAIGN)}>{row.DCAMPAIGN}</a></TableCell>
                                            <TableCell align="left">{row.DCOUNTRY}</TableCell>
                                            <TableCell align="left">{row.LISTA_DLOCATION}</TableCell>
                                            <TableCell align="left">{CommonUtilities.getFormatDate(row.DATA_INSERIMENTO)}</TableCell>
                                            <TableCell align="left">{CommonUtilities.getFormatDate(row.BEGIN_DATE)}</TableCell>
                                            <TableCell align="left">{CommonUtilities.getFormatDate(row.END_DATE)}</TableCell>
                                            <TableCell align="center"><span className={chipClass(row)}>{row.STATUS_DESC === 'CONFIRMED' ? 'AWAITING PAYMENT' : row.STATUS_DESC}{row.REJECTED === 'Y' ? ' (reject.)' : ''}</span></TableCell>
                                            <TableCell align="center">{row.CUSTOM_CREATIVE_ID !== null ? <img className="thumb" src={`${config.CONTENT_DISTRIBUTION_URL}/thumbs/${row.CUSTOM_CREATIVE_ID}.png`} alt="" /> : row.GLOOH_RENDERED === 'Y' ? <img className="thumb" src={`${config.CONTENT_DISTRIBUTION_URL}/thumbs/${row.GLOOH_CREATIVE_ID}.png`} alt="" /> : 'Rendering'}</TableCell>
                                            <TableCell align="left"><a href="#" onClick={(event) => gotoUser(event, row.KUSER_CREATION)}>{row.DUSER_CREATION}</a></TableCell>
                                            <TableCell align="right">&euro;{CommonUtilities.formatPositiveIntNum(row.TOTAL_COST)}</TableCell>
                                            <TableCell align="left">{row.STRIPE_CLIENT_SECRET}</TableCell>
                                            <TableCell align="left">{row.CC_CAMPAIGN_ID}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Pagination count={numPages} siblingCount={0} page={page} variant="outlined" color="primary" size='medium' onChange={handleChangePage}/>
                        </Paper>
                    </CardContent>

                </Card>          
            </ThemeProvider>              
        </div>
    )
}

export default CampaignList;

import React, { useState, useContext, useRef, useEffect, createRef } from 'react'
import "./signin.css";

import { useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import labelData from '../../labels.json'

import { inputOnlyNumbers } from '../../utils/CommonUtilities';
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { useValidPassword, useValidEmail } from '../../hooks/useAuthHooks'
import { Password, Email } from '../../components/authComponents'
import { AuthContext } from '../../contexts/authContext'
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import config from '../../config';


const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
})

const SignIn: React.FunctionComponent<{}> = () => {
  const classes = useStyles()
  const { email, setEmail, emailIsValid } = useValidEmail('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false);
  const labels = labelData.labels;

  const [openDialogMFA, setOpenDialogMFA] = useState(false);
	const [codeTOTP, setCodeTOTP] = useState('');
  
  const isValid = !emailIsValid || email.length === 0 || !passwordIsValid || password.length === 0
  const history = useHistory()
  const authContext = useContext(AuthContext)
  const myRef = createRef<HTMLButtonElement>()
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() =>  {  
    if(myRef.current) {
      myRef.current.focus();
    }
  }, []);

  const signInClicked = async (event:React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    
    try {
      
      let response = await authContext.signInWithEmail(email, password);

      /* if(response === 'SOFTWARE_TOKEN_MFA'){
				setOpenDialogMFA(true);
        setLoading(false);
			}else{ */
      //console.log('post signin')
        history.push('/home');
        setLoading(false);
      /* } */
    } catch (err: any) {
      if(config.FORCE_MFA_TOTP){
        setOpenDialogMFA(true);
      }
      //setError(labels["error-login"]);
      setLoading(false);
    } 
  }

  /* const submitTOTP = async() => {
		try{
      setLoading(true);
			await authContext.signInWithTOTP(codeTOTP, email, password);

			handleCloseDialog();
			history.push('/home');
      setLoading(false);
		}catch(e){
      setLoading(false);
      setError(labels["error-login"]);
			handleCloseDialog();
		}
	} */

  const handleCloseDialog = () => {
		setCodeTOTP('');
		setOpenDialogMFA(false);
	}

  const handleClickShowPassword = (event:React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  }

  const goToForgot = (event:React.MouseEvent<HTMLAnchorElement>) =>{
    event.preventDefault();
    history.push('/forgotpassword');
  }

  return (
    <>
      <form noValidate onSubmit={ (event:React.FormEvent<HTMLFormElement>) => signInClicked(event) } > 
        <Grid className={classes.root}  container direction="row" style={{justifyContent: 'center', alignItems: 'center'}} >
          {loading && (<div className="circular-progress"><CircularProgress /></div>)}
        
          <Grid xs={11} sm={6} lg={4} container direction="row" item style={{justifyContent: 'center', alignItems: 'center'}}>
          
            <Paper style={{ width: '100%', padding: 32 }}>
              <Grid container direction="column" style={{justifyContent: 'center', alignItems: 'center'}}>
                {/* Title */}
                <Box m={2} style={{position: 'relative'}}>
                  {/*<Typography variant="h3">Sign in</Typography>*/}
                  {/* <img src="assets/images/logo-golive.png" alt="" style={{width: 250}} /> */}
                  <img src="assets/images/systrategy_logo.svg" style={{width: 250}} alt=""/>
                  {/*<img src="assets/images/santa.png" alt="" style={{position: 'absolute', transform: 'rotate(63deg)', top: '4px', right: '-25px', width: '40px'}} />*/}
                  <div className="lbl-version">v1.5.0</div> 
                </Box>
                

                {/* Sign In Form */}
                <Box width="80%" m={1}>
                  <Email emailIsValid={emailIsValid} setEmail={setEmail} />
                  {/* <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />{' '} */}
                </Box>
                <Box width="80%" m={1} className="box-password">
                  <Password label="Password" passwordIsValid={true} setPassword={setPassword} showPassword={showPassword} />

                  <span className="input-icon" tabIndex={-1} role="button" onClick={(event:React.MouseEvent<HTMLSpanElement>) => handleClickShowPassword(event)} >
                    {showPassword 
                    ? 
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path></svg>
                    :
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path></svg>
                    }
                  </span>

                </Box>

                {/* Error */}
                <Box mt={2}>
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                </Box>

                {/* Buttons */}
                <Box mt={2} sx={{marginTop: 0}}>
                  <Grid container direction="row">
                    <Box sx={{display: 'flex', flexDirection: 'column'}} m={1}>
                      <button 
                        type="submit"
                        ref={myRef}
                        autoFocus={true}
                        className="button-classic">
                          Sign In
                      </button>
                      <a 
                        href="#" 
                        className='forgot-link'
                        onClick={goToForgot}>
                        Forgot Password?
                      </a>
                    </Box>
                  </Grid>
                </Box>

                <div className="logo-sys">        
                    <a href="https://www.systrategy.it" target="_blank" rel="noreferrer"><img src="assets/images/systrategy_logo_header.svg" alt="" style={{width: 100}} /></a>
                </div>

              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
      {/* <Dialog className='dialog-mfa-code' open={openDialogMFA} onClose={handleCloseDialog}>
				<DialogTitle>Multi-Factor Authentication</DialogTitle>
				<DialogContent className='align-mfa-center'>
					<DialogContentText className='font-12'>
						Open your authentication app and enter a valid MFA code to complete sign-in
					</DialogContentText>
					<FormControl className="signin-credentials-wrapper">
						<OutlinedInput
							className       ="center-text"
							name            ="totp"
							id              ="totp"
							placeholder     ="Code"
							type            ='text'
              autoComplete    ='off'
							inputProps		={{maxLength: 6}}
							value           ={ codeTOTP }
							onChange        ={ e => { if(inputOnlyNumbers(e.target.value))setCodeTOTP(e.target.value); } }
							spellCheck      ={ false }
						/>
					</FormControl>
				</DialogContent>
				<DialogActions className='flex-box-between'>
					<Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
					<Button onClick={submitTOTP} variant='contained' disabled={codeTOTP.length!==6}>Submit</Button>
				</DialogActions>
			</Dialog> */}
    </>
  )
}

export default SignIn

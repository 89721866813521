import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { AuthContext } from '../contexts/authContext'
import Header from './components/header';
import PromoCodesDetail from './admin/promoCodeDetail.js';

export default function PromoCodes() {
  const history = useHistory();

  const auth = useContext(AuthContext);

  return (
    <Grid container>
      <Header />

      <PromoCodesDetail />

    </Grid>
  )
}

import React, { useState, useEffect, useContext } from 'react'
import "./userList.css";
import config from '../../config';
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../contexts/authContext';
import { Country } from '../../App';
import axios from 'axios';
import CommonUtilities from "../../components/CommonUtilities";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const theme = createTheme({
    palette: {
        primary: {
            main: "#0c86c4",
        },    
    },
});

const UserList = (props) =>{
    const history = useHistory();
    const auth = useContext(AuthContext);
    const [country, setCountry] = useContext(Country);        
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [adminRows, setAdminRows] = useState([]);
    const [selectedHeader, setSelectedHeader] = useState(localStorage.getItem('sort-header')!==null ? localStorage.getItem('sort-header') : 'KUSER');
    const [sortDirection, setSortDirection] = useState(localStorage.getItem('sort-direction')!==null ? localStorage.getItem('sort-direction') : 'desc');
    const [page, setPage] = useState(localStorage.getItem('page')!==null ? parseInt(localStorage.getItem('page')) : 1);
    const [firstRecord, setFirstRecord] = useState(localStorage.getItem('firstRecord')!==null ? parseInt(localStorage.getItem('firstRecord')) : 1);
    const [numRecords, setNumRecords] = useState(10);
    const [numPages, setNumPages] = useState(1);
    const [adminNumPages, setAdminNumPages] = useState(1);
    const [searchBy, setSearchBy] = useState(localStorage.getItem('filter-type')!==null ? parseInt(localStorage.getItem('filter-type')) : 1);
    const [searchTxt, setSearchTxt] = useState(localStorage.getItem('filter-val')!==null ? localStorage.getItem('filter-val') : "");
    const [handlerDebounce, setHandlerDebounce] = useState(null);
    const [tabValue, setTabValue] = useState(localStorage.getItem('userListTab') !== null ? parseInt(localStorage.getItem('userListTab')) : 0);
    const [superAdmin, setSuperAdmin] = useState(localStorage.getItem('super_admin') !== null ? localStorage.getItem('super_admin') : 'N');

    useEffect(() => {
        localStorage.removeItem('page');
        localStorage.removeItem('firstRecord');
        localStorage.removeItem('filter-type');
        localStorage.removeItem('filter-val');
        localStorage.removeItem('userListTab');
        localStorage.removeItem('sort-header');
        localStorage.removeItem('sort-direction');
    }, [])

    useEffect(() => {
        loadGrid();
    }, [firstRecord, selectedHeader, sortDirection])

    useEffect(() => {
        if(!loading){
            clearTimeout(handlerDebounce);
            setHandlerDebounce(setTimeout(() => {
                loadGrid();
            }, 600));
        }
    }, [searchTxt]);

    useEffect(() => {
        if(!loading){
            loadGrid();
        }
    }, [searchBy]);



    const loadGrid = async() => {
        setLoading(true);

        let url = `${config.API_URL}/users`;

        let cognome = "";
        let nome = "";
        let emailAddress = "";

        if(searchBy === 1){
            nome = searchTxt;
        }else if(searchBy === 2){
            cognome = searchTxt;
        }else if(searchBy === 3){
            emailAddress = searchTxt;
        }

        const kCountry = country.split('|')[0];

        const params = {
            kCountry,
            nome,
            cognome,
            emailAddress,
            firstRecord,
            numRecords,
            orderByField: selectedHeader,
            orderByMode: sortDirection.toUpperCase(),
            flagAutoRegUsers: 'Y'
        };

        try{
            let response = await axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },
                    params
                }
            )
            
            if(response.status === 200){
                if(response.data.length > 0){
                    setRows(response.data);
                    setNumPages(Number.isInteger(response.data[0].NUM_TOT/numRecords) ? response.data[0].NUM_TOT/numRecords : Math.floor(response.data[0].NUM_TOT/numRecords)+1);
                }else{
                    setRows([]);
                    setNumPages(0);
                }
            }

            if(superAdmin === 'Y'){    
                const params = {
                    nome,
                    cognome,
                    emailAddress,
                    firstRecord,
                    numRecords,
                    orderByField: selectedHeader,
                    orderByMode: sortDirection.toUpperCase(),
                    flagAutoRegUsers: 'N'
                };

                response = await axios.get(
                    url,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                        },
                        params
                    }
                )
                
                if(response.status === 200){
                    if(response.data.length > 0){
                        setAdminRows(response.data);
                        setAdminNumPages(Number.isInteger(response.data[0].NUM_TOT/numRecords) ? response.data[0].NUM_TOT/numRecords : Math.floor(response.data[0].NUM_TOT/numRecords)+1);
                    }else{
                        setAdminRows([]);
                        setAdminNumPages(0);
                    }
                }
            }

        }catch(error){
            if(error.response.status === 401){ //status 401: Unauthorized
                auth.signOut();
            }else{
                console.error(error.response);
            }
        }
        setLoading(false);
    }

    const sortTable = (columnId, sortDirection) => {
        let sortValue = ''
        //controllo per resettare a desc il sorting nel caso si cliccasse su un header differente dal precedente
        if(columnId !== selectedHeader){
            sortValue = 'desc';
        }else{
            sortValue = sortDirection==='desc' ? 'asc' : 'desc';
        }
        setSortDirection(sortValue);
        setSelectedHeader(columnId);
    }

    const goToUserDetail = (event, kUser, admin='N') => {
        event.preventDefault();
        localStorage.setItem('page', page);
        localStorage.setItem('firstRecord', firstRecord);
        localStorage.setItem('filter-type', searchBy);
        localStorage.setItem('filter-val', searchTxt);
        localStorage.setItem('userListTab', tabValue);
        localStorage.setItem('sort-header', selectedHeader);
        localStorage.setItem('sort-direction', sortDirection);
        if(admin!=='N'){
            //se admin è Y si customizza la url per farlo vedere alla accountDetail
            history.push(`/user/${kUser}&adm`);
        }else{
            history.push(`/user/${kUser}`);
        }
    }

    const chipClass = (row) => {
        if(!row.COGNITO_SUB){
            return 'tag-waiting';
        }
        else if(row.COGNITO_SUB && row.FLAG_ATTIVO==='Y'){
            return 'tag-enabled';
        }
        else if(row.COGNITO_SUB && row.FLAG_ATTIVO==='N'){
            return 'tag-disabled'
        }
    }

    const handleChangePage = (event,value) => {
        if(value === 1){
            setPage(1);
            setFirstRecord(1)
        }else{
            setPage(value);
            setFirstRecord(((value-1)*numRecords)+1);
        }
    }

    const handleChangeSearchBy = (event) => {
        setSearchBy(event.target.value);
    };

    const handleChangeSearchTxt = (event) => {
        setSearchTxt(event.target.value);
    };

    const handleChangeTab = (event, value) => {
        setFirstRecord(1);
        setPage(1);
        setTabValue(value);
        //loadGrid(); 
    }  

    return (
        <div className="user-list">
            <ThemeProvider theme={theme}>            
                {loading && (<div className="circular-progress"><CircularProgress /></div>)}

                <Card style={{width: '100%'}}>
                    <CardHeader
                        title="Users"
                    />
                    {superAdmin === 'Y' && (<Tabs value={tabValue} sx={{marginLeft: '16px'}} onChange={handleChangeTab} aria-label="basic tabs example">
                        <Tab label="Users" id='simple-tab-1'/>
                        <Tab label="Admins" id='simple-tab-2'/>
                    </Tabs>)}
                    {tabValue === 0 ? (
                    <CardContent>

                    <div className="filters">
                            <FormControl className="frm-ctrl">
                                <Select
                                    value={searchBy}
                                    onChange={handleChangeSearchBy}
                                    variant="standard" 
                                    size="small"
                                    style={{width: 100}}
                                    color="primary"
                                    >
                                        <MenuItem value={1}>Name</MenuItem>
                                        <MenuItem value={2}>Surname</MenuItem>
                                        <MenuItem value={3}>Email</MenuItem>
                                </Select>
                            </FormControl>
                            
                            <FormControl>
                                <TextField
                                    placeholder="Search Users by attribute"
                                    value={searchTxt}
                                    onChange={handleChangeSearchTxt}
                                    variant="standard" 
                                    size="small"
                                    style={{width: 250}}
                                    color="primary"
                                />
                            </FormControl>

                        </div>

                        <Paper className="grid-cnt">           
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 610 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                id={'KUSER'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'KUSER'}
                                                    active={selectedHeader === 'KUSER' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('KUSER', sortDirection)}}
                                                    >User
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'NOME'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'NOME'}
                                                    active={selectedHeader === 'NOME' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('NOME', sortDirection)}}
                                                    >Name
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'COGNOME'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'COGNOME'}
                                                    active={selectedHeader === 'COGNOME' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('COGNOME', sortDirection)}}
                                                    >Surname
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'EMAIL_ADDRESS'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'EMAIL_ADDRESS'}
                                                    active={selectedHeader === 'EMAIL_ADDRESS' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('EMAIL_ADDRESS', sortDirection)}}
                                                    >Email
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'LISTA_DCOUNTRY'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'LISTA_DCOUNTRY'}
                                                    active={selectedHeader === 'LISTA_DCOUNTRY' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('LISTA_DCOUNTRY', sortDirection)}}
                                                    >Country
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'COMPANY_NAME'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'COMPANY_NAME'}
                                                    active={selectedHeader === 'COMPANY_NAME' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('COMPANY_NAME', sortDirection)}}
                                                    >Company name
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'FLAG_ATTIVO'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'FLAG_ATTIVO'}
                                                    active={selectedHeader === 'FLAG_ATTIVO' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('FLAG_ATTIVO', sortDirection)}}
                                                    >Status
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'DATA_INSERIMENTO'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'DATA_INSERIMENTO'}
                                                    active={selectedHeader === 'DATA_INSERIMENTO' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('DATA_INSERIMENTO', sortDirection)}}
                                                    >Creation date
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'DATA_ULTIMA_MODIFICA'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'DATA_ULTIMA_MODIFICA'}
                                                    active={selectedHeader === 'DATA_ULTIMA_MODIFICA' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('DATA_ULTIMA_MODIFICA', sortDirection)}}
                                                    >Last edit date
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="table-body">
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.KUSER}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                            <TableCell ><a href="#" onClick={(event) => goToUserDetail(event, row.KUSER)}>{row.KUSER}</a></TableCell>
                                            <TableCell align="left"><a href="#" onClick={(event) => goToUserDetail(event, row.KUSER)}>{row.NOME}</a></TableCell>
                                            <TableCell align="left"><a href="#" onClick={(event) => goToUserDetail(event, row.KUSER)}>{row.COGNOME}</a></TableCell>
                                            <TableCell align="left"><a href="#" onClick={(event) => goToUserDetail(event, row.KUSER)}>{row.EMAIL_ADDRESS}</a></TableCell>
                                            <TableCell align="left">{row.LISTA_DCOUNTRY}</TableCell>
                                            <TableCell align="left">{row.COMPANY_NAME}</TableCell>
                                            <TableCell align="center"><span className={chipClass(row)}>{!row.COGNITO_SUB ? 'Awaiting confirmation' : row.FLAG_ATTIVO === 'Y' ? 'Enabled' : 'Disabled'}</span></TableCell>
                                            <TableCell align="left">{CommonUtilities.getFormatDateTime(row.DATA_INSERIMENTO, 'MMMM Do YYYY HH:mm:ss')}</TableCell>
                                            <TableCell align="left">{CommonUtilities.getFormatDateTime(row.DATA_ULTIMA_MODIFICA, 'MMMM Do YYYY HH:mm:ss')}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Pagination count={numPages} siblingCount={0} page={page} variant="outlined" color="primary" size='medium' onChange={handleChangePage}/>
                        </Paper>
                    </CardContent>)
                    :
                    (<CardContent>
                        <div className="filters">
                            <FormControl className="frm-ctrl">
                                <Select
                                    value={searchBy}
                                    onChange={handleChangeSearchBy}
                                    variant="standard" 
                                    size="small"
                                    style={{width: 100}}
                                    color="primary"
                                    >
                                        <MenuItem value={1}>Name</MenuItem>
                                        <MenuItem value={2}>Surname</MenuItem>
                                        <MenuItem value={3}>Email</MenuItem>
                                </Select>
                            </FormControl>
                            
                            <FormControl>
                                <TextField
                                    placeholder="Search Users by attribute"
                                    value={searchTxt}
                                    onChange={handleChangeSearchTxt}
                                    variant="standard" 
                                    size="small"
                                    style={{width: 250}}
                                    color="primary"
                                />
                            </FormControl>

                        </div>

                        <Paper className="grid-cnt">           
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 610 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                id={'KUSER'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'KUSER'}
                                                    active={selectedHeader === 'KUSER' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('KUSER', sortDirection)}}
                                                    >User
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'NOME'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'NOME'}
                                                    active={selectedHeader === 'NOME' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('NOME', sortDirection)}}
                                                    >Name
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'COGNOME'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'COGNOME'}
                                                    active={selectedHeader === 'COGNOME' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('COGNOME', sortDirection)}}
                                                    >Surname
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'EMAIL_ADDRESS'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'EMAIL_ADDRESS'}
                                                    active={selectedHeader === 'EMAIL_ADDRESS' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('EMAIL_ADDRESS', sortDirection)}}
                                                    >Email
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'LISTA_DCOUNTRY'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'LISTA_DCOUNTRY'}
                                                    active={selectedHeader === 'LISTA_DCOUNTRY' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('LISTA_DCOUNTRY', sortDirection)}}
                                                    >Country
                                                </TableSortLabel>
                                            </TableCell>                                            
                                            <TableCell
                                                id={'FLAG_ATTIVO'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'FLAG_ATTIVO'}
                                                    active={selectedHeader === 'FLAG_ATTIVO' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('FLAG_ATTIVO', sortDirection)}}
                                                    >Status
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'DATA_INSERIMENTO'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'DATA_INSERIMENTO'}
                                                    active={selectedHeader === 'DATA_INSERIMENTO' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('DATA_INSERIMENTO', sortDirection)}}
                                                    >Creation date
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                id={'DATA_ULTIMA_MODIFICA'}
                                                align="left"
                                                >
                                                <TableSortLabel
                                                    id={'DATA_ULTIMA_MODIFICA'}
                                                    active={selectedHeader === 'DATA_ULTIMA_MODIFICA' ? true : false}
                                                    direction={sortDirection}
                                                    onClick={() => {sortTable('DATA_ULTIMA_MODIFICA', sortDirection)}}
                                                    >Last edit date
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="table-body">
                                    {adminRows.map((row) => (
                                        <TableRow
                                            key={row.KUSER}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                            <TableCell ><a href="#" onClick={(event) => goToUserDetail(event, row.KUSER, row.FLAG_ADMIN)}>{row.KUSER}</a></TableCell>
                                            <TableCell align="left"><a href="#" onClick={(event) => goToUserDetail(event, row.KUSER, row.FLAG_ADMIN)}>{row.NOME}</a></TableCell>
                                            <TableCell align="left"><a href="#" onClick={(event) => goToUserDetail(event, row.KUSER, row.FLAG_ADMIN)}>{row.COGNOME}</a></TableCell>
                                            <TableCell align="left"><a href="#" onClick={(event) => goToUserDetail(event, row.KUSER, row.FLAG_ADMIN)}>{row.EMAIL_ADDRESS}</a></TableCell>
                                            <TableCell align="left">{row.LISTA_DCOUNTRY}</TableCell>
                                            <TableCell align="center"><span className={chipClass(row)}>{!row.COGNITO_SUB ? 'Awaiting confirmation' : row.FLAG_ATTIVO === 'Y' ? 'Enabled' : 'Disabled'}</span></TableCell>
                                            <TableCell align="left">{CommonUtilities.getFormatDateTime(row.DATA_INSERIMENTO, 'MMMM Do YYYY HH:mm:ss')}</TableCell>
                                            <TableCell align="left">{CommonUtilities.getFormatDateTime(row.DATA_ULTIMA_MODIFICA, 'MMMM Do YYYY HH:mm:ss')}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Pagination count={adminNumPages} siblingCount={0} page={page} variant="outlined" color="primary" size='medium' onChange={handleChangePage}/>
                        </Paper>
                    </CardContent>)}
                </Card>
            </ThemeProvider>
        </div>
    )
}

export default UserList;

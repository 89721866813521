import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import { useValidCode, useValidPassword, useValidEmail } from '../../hooks/useAuthHooks'
import { Code, Password, Email } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'

export default function ForgotPassword() {

  const { code, setCode, codeIsValid } = useValidCode('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const { email, setEmail, emailIsValid } = useValidEmail('')
  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)
  const [showPassword, setShowPassword] = useState(false);

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')

  const isValidRequest =
    !emailIsValid ||
    email.length === 0

  const isValid =
    !codeIsValid ||
    code.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0 ||
    !(password === passwordConfirm)

  const history = useHistory()

  const authContext = useContext(AuthContext)

  const sendRequestCode = async (event:React.MouseEvent<HTMLButtonElement>) => {
    //chiamata a cognito per ottenere il codice da inserire
    event.preventDefault();
    try{
      await authContext.sendCode(email);
      setReset(true);
    } catch (err) {
      if (err instanceof Error){
        setError(err.message);
      }
    }
  }

  const resetPassword = async (event:React.MouseEvent<HTMLButtonElement>) => {
    //chiamata a cognito che cambia la password con quella nuova dopo aver ricevuto il codice da inserire
    event.preventDefault();
    try {
      await authContext.forgotPassword(email, code, password)
      setReset(true)
      history.push('/')
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const updatePassword = (
    <>
      <Box width="80%" m={1}>
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>

      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row">
          <Box m={1}>
            <Button onClick={() => history.goBack()} variant="outlined">
              Back
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isValidRequest} color="primary" variant="contained" onClick={sendRequestCode}>
              Send Code
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  const passwordReset = (
    <>
      <Box width="80%" m={1}>
        <Code codeIsValid={codeIsValid} setCode={setCode} />
      </Box>
      <Box width="80%" m={1}>
        <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} showPassword={showPassword} />
      </Box>
      <Box width="80%" m={1}>
        <Password label="Confirm Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} showPassword={showPassword} />
      </Box>

      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      <Box mt={2}>
        <Grid container direction="row">
          <Box m={1}>
            <Button 
              onClick={() => {
                setReset(false);
                setEmail('');
                setCode('');
                setPassword('');
                setPasswordConfirm('');
              }}
              variant="outlined">
              Back
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isValid} onClick={resetPassword} color="primary" variant="contained">
              Reset
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  return (
    <Grid container direction="row" sx={{height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
      <Grid xs={11} sm={6} lg={4} container direction="row">
        <Paper style={{ width: '100%', padding: 16 }}>
          <Grid container direction="column" sx={{justifyContent: 'center', alignItems: 'center'}}>
            {/* Title */}
            <Box m={3}>
              {/* <img src="assets/images/logo-golive.png" alt="" style={{width: 250}} /> */}
              <img src="assets/images/systrategy_logo.svg" style={{width: 250}} alt=""/>
            </Box>

            {!reset ? updatePassword : passwordReset}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}